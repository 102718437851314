import { useSelector, useDispatch, useStore, shallowEqual } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import fetchDataFromNormalizer from '@utils/fetchDataFromNormalizer';

export const useSelectorToStore = (mapProps, needShallowEqualCheck = true) => {
  const data = useSelector(
    mapProps,
    needShallowEqualCheck ? shallowEqual : undefined
  );
  const stateData = useStore().getState(); // Hack --- need to change with useSelector #todo (Priya / Saurabh)
  return fetchDataFromNormalizer(cloneDeep(data), cloneDeep(stateData));
};

export const useDispatchToStore = () => {
  const dispatch = useDispatch();
  return dispatch;
};

export const useSelectorWithShallowEqual = (selector) => {
  return useSelector(selector, shallowEqual);
};
