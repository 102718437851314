/* eslint-disable import/prefer-default-export */

export const getCityRequestEndpoint = () =>
  'v1/users/experiment_data/offline_centre/';

export const getOfflineCentrePlansEndpoint = (city) =>
  `v1/subscriptions/offline/list/?city_uid=${city}&upgrade=true`;

export const getReferralPlansEndpoint = (referralCode, city) =>
  `v1/subscriptions/validate_referral_all_offline_subscriptions/?referral=${referralCode}&city_uid=${city}`;

export const getIsReferralValid = (referralCode, goalUID) =>
  `v1/asgard/centre/validate-referral?referral_code=${referralCode}&goal_uid=${goalUID}`;

export const getEnquiryFormGoalsEndpoint = () =>
  'v1/topology/user_topology/list/?offline_only=1';

export const getCounsellorNameEndpoint = (username) => `v1/user/${username}/`;

export const getCityCentreEndpoint = () =>
  'v1/offline_centres/centres/cities?status=Active';

export const getGoalSpecificCityCentreEndpoint = (goalUID) =>
  `v1/asgard/centre/offline/cities?goal_uid=${goalUID}`;

export const getEnquiryFormEndpoint = () =>
  'v1/users/experiment_data/offline_centre_enquiry_form/';
