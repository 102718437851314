import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getIsLoggedIn } from '@api/selectors/auth';
import Image from '@base/FutureImage';
import styled from '@emotion/styled';
import { useIsMobile } from '@stores/AppCommonData';
import { useToggleable } from '@stores/toggleables';
import { Fonts, mediaQuery, Spacings } from '@styles/index';
import pushRoute from '@utils/pushRoute';
import Typography from 'aquilla/core/atoms/Typography';
import Button from 'aquilla/core/molecules/Button';
import { LOGIN_DRAWER } from '@constants/toggleables';
import { useEffect, useState } from 'react';
import RightChevron from 'aquilla/icons/actions/RightChevron';
import useDarkModeStatus from '@stores/useDarkModeStatus';
import { getIsDarkModeOn } from '@stores/selectors/darkModeStatusSelectors';
import { useUpdateSelectedGoal } from '@api/hooks/topology/useSelectedGoal';
import { followGoal } from '@api/fetchers/goal/updaters';
import {
  LOGIN_SIGNUP_FIELD_CLICKED,
  ONBOARDING_GOAL_SELECTED
} from './constants/event';
import popularGoalsData from './constants';
import GoalSearch from './GoalSearch';

const Container = styled.div`
  width: 1136px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: ${Spacings.SPACING_12B};
  padding-bottom: ${Spacings.SPACING_12B};
  ${mediaQuery.uptoMobile} {
    width: 100%;
    padding-top: ${Spacings.SPACING_8B};
    padding-bottom: ${Spacings.SPACING_8B};
    flex-direction: column;
  }
`;

const GoalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Spacings.SPACING_4B};
  gap: ${Spacings.SPACING_10B};
  ${mediaQuery.uptoMobile} {
    margin-top: ${Spacings.SPACING_7B};
    gap: ${Spacings.SPACING_4B};
  }
`;

const BlockWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: ${Spacings.SPACING_6B};
  ${mediaQuery.uptoMobile} {
    display: flex;
    flex-direction: column;
    gap: ${Spacings.SPACING_0B};
  }
`;

const Block = styled.div`
  width: 266px;
  height: 280px;
  align-items: center;
  background: var(--color-base-1);
  border: ${Spacings.SPACING_1} solid var(--color-divider);
  border-radius: ${Spacings.SPACING_10};
  display: flex;
  flex-direction: column;
  gap: ${Spacings.SPACING_4B};
  padding-top: ${Spacings.SPACING_18B};
  cursor: pointer;
  ${mediaQuery.uptoMobile} {
    width: 100%;
    height: 64px;
    flex-direction: row;
    justify-content: flex-start;
    border: none;
    padding: ${Spacings.SPACING_4B};
    background: var(--color-base-1);
  }
  :hover {
    background: var(--color-base-light-0);
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacings.SPACING_2B};
  margin-bottom: ${Spacings.SPACING_2B};
  ${mediaQuery.uptoMobile} {
    gap: ${Spacings.SPACING_1B};
    padding: ${Spacings.SPACING_0B} ${Spacings.SPACING_4B};
    margin-bottom: ${Spacings.SPACING_0B};
  }
`;

const StyledButton = styled(Button)`
  gap: ${Spacings.SPACING_2B};
  width: 196px;
  height: 48px;
  background: var(--color-base-1);
  border: ${Spacings.SPACING_1} solid var(--color-divider-tertiary);
  border-radius: ${Spacings.SPACING_2B};
  ${mediaQuery.uptoMobile} {
    ${'' /* width: 328px; */}
    width:100%;
    height: 40px;
  }
`;

const ChevronWrapper = styled.div`
  ${mediaQuery.uptoMobile} {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
`;

const GoalNameWrapper = styled(Typography)`
  width: 234px;
  text-align: center;
  ${mediaQuery.uptoMobile} {
    text-align: left;
  }
`;

const SubHeaderContainer = styled.div`
  ${mediaQuery.uptoMobile} {
    * {
      font-size: ${Fonts.SMALL};
      line-height: ${Spacings.SPACING_5B};
    }
  }
`;
const PopularGoalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacings.SPACING_8B};
  ${mediaQuery.uptoMobile} {
    gap: ${Spacings.SPACING_0B};
  }
`;

const SubHeader = () => {
  return (
    <SubHeaderContainer>
      <Typography variant="h5" color="#08BD80">
        200+{' '}
        <Typography as="span" variant="h5">
          exams available for your preparation
        </Typography>
      </Typography>
    </SubHeaderContainer>
  );
};
const CTAWrapper = styled.div`
  ${mediaQuery.uptoMobile} {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: ${Spacings.SPACING_0B} ${Spacings.SPACING_4B};
  }
`;

const PopularGoalTitle = styled(Typography)`
  padding: ${Spacings.SPACING_0B};
  ${mediaQuery.uptoMobile} {
    font-weight: ${Fonts.LIGHT};
    font-size: ${Fonts.ULTRA_SMALL};
    line-height: ${Spacings.SPACING_4B};
    color: var(--color-text-secondary);
  }
`;
const GoalCard = ({ onGoalClick, isMobile, goalData, isDarkModeOn }) => {
  const handleOnClick = () => {
    if (typeof onGoalClick === 'function') onGoalClick(goalData.uid);
  };
  return (
    <Block onClick={handleOnClick}>
      <Image
        src={isDarkModeOn ? goalData?.darkIcon : goalData?.icon}
        alt="goal-icon"
        width={isMobile ? 40 : 80}
        height={isMobile ? 40 : 80}
      />
      <GoalNameWrapper variant={isMobile ? 'h6' : 'h4'}>
        {goalData?.name}
      </GoalNameWrapper>
      <ChevronWrapper>{isMobile && <RightChevron />}</ChevronWrapper>
    </Block>
  );
};

const PopularGoals = ({ onGoalClick, handleSeeAll }) => {
  const isDarkModeOn = useDarkModeStatus(getIsDarkModeOn);
  const isMobile = useIsMobile();
  return (
    <GoalContainer>
      <PopularGoalsWrapper>
        <PopularGoalTitle variant="h3">Popular goals</PopularGoalTitle>
        <BlockWrapper>
          {popularGoalsData.map((goalData) => (
            <GoalCard
              key={goalData.uid}
              onGoalClick={onGoalClick}
              isDarkModeOn={isDarkModeOn}
              isMobile={isMobile}
              goalData={goalData}
            />
          ))}
        </BlockWrapper>
      </PopularGoalsWrapper>
      <CTAWrapper>
        <StyledButton
          variant="secondary"
          label="See all goals (200+)"
          onClick={handleSeeAll}
        />
      </CTAWrapper>
    </GoalContainer>
  );
};

const GoalSelection = ({ sendEvent }) => {
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const { openElement: openLoginDrawer } = useToggleable(LOGIN_DRAWER);
  const [isUID, setIsUID] = useState();
  const [isSeeAllRequesting, setIsSeeAllRequesting] = useState(null);

  const selectGoal = useUpdateSelectedGoal();

  const gotoSelectedGoal = async (goal) => {
    const route = {
      href: '/goal/[goalSlug]/[goalUID]',
      as: `/goal/-/${goal.uid}`
    };
    await followGoal(goal.uid, true);
    selectGoal(goal.uid);
    window.open(route.as, '_self');
  };

  const handleSeeAll = () => {
    if (!isLoggedIn) {
      sendEvent({
        name: LOGIN_SIGNUP_FIELD_CLICKED,
        eventProperties: {
          last_primary_source: 'Unacademy Home',
          last_primary_source_section: 'See all goals'
        }
      });
      openLoginDrawer();
      setIsSeeAllRequesting(true);
    } else {
      pushRoute('/explore');
      setIsSeeAllRequesting(null);
    }
  };

  const onGoalClick = (goalUID) => {
    sendEvent({
      name: ONBOARDING_GOAL_SELECTED,
      eventProperties: {
        last_primary_source: 'Unacademy Home',
        last_primary_source_section: 'Goal Tile'
      }
    });
    if (!isLoggedIn) {
      sendEvent({
        name: LOGIN_SIGNUP_FIELD_CLICKED,
        eventProperties: {
          last_primary_source: 'Unacademy Home',
          last_primary_source_section: 'Goal Tile'
        }
      });
      openLoginDrawer();
      setIsUID(goalUID);
    } else {
      gotoSelectedGoal({ uid: goalUID });
      setIsUID(null);
    }
  };

  useEffect(() => {
    if (isLoggedIn && isUID) onGoalClick(isUID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isUID]);

  useEffect(() => {
    if (isLoggedIn && isSeeAllRequesting) handleSeeAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isSeeAllRequesting]);

  return (
    <Container id="goal-selection">
      <Header>
        <Typography variant="h2">Select your goal / exam</Typography>
        <SubHeader />
      </Header>
      <GoalSearch />
      <PopularGoals onGoalClick={onGoalClick} handleSeeAll={handleSeeAll} />
    </Container>
  );
};
export default GoalSelection;
