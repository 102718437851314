export const LESSON_PAGE = 'https://unacademy.onelink.me/cjVw/9705646e';
export const HOME_PAGE = 'https://unacademy.onelink.me/cjVw/49628d15';
export const EDUCATOR_PAGE = 'https://unacademy.onelink.me/cjVw/d0fec5f1';
export const SPECIAL_CLASS_PAGE = 'https://unacademy.onelink.me/cjVw/77ccaabb';
export const COURSE_PAGE = 'https://unacademy.onelink.me/cjVw/f4d11543';
export const GOAL_LANDING_PAGE = 'https://unacademy.onelink.me/cjVw/c12dff3a';
export const BATCHES_PAGE = 'https://unacademy.onelink.me/cjVw/254b36ff';
export const SYLLABUS_PAGE = 'https://unacademy.onelink.me/cjVw/dd8eed1b';
export const GOAL_PAGE = 'https://unacademy.onelink.me/cjVw/b743d86b';
export const MWEB_NOT_AVAILABLE_PAGE =
  'https://unacademy.onelink.me/cjVw/e2519b03';
