import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getIsLoggedIn, getMePhone } from '@api/selectors/auth';
import { LOGIN_DRAWER } from '@constants/toggleables';
import styled from '@emotion/styled';
import useLoginStore from '@stores/login/loginStore';
import { getSetLocalPhoneNumber } from '@stores/selectors/login';
import { useToggleable } from '@stores/toggleables';
import { Fonts, mediaQuery, Spacings } from '@styles/index';
import Typography from 'aquilla/core/atoms/Typography';
import Field from '@base/Form/Field';
import useForm from '@base/Form/useForm';
import { isRequired } from '@cont/OfflineCentres/EnquiryPage/utils';
import fetch from '@api/fetchers';
import NumberField from '@base/NumberField/NumberField';
import { InputAdornment } from '@material-ui/core';
import CountryMenu from '@cont/Login/CountryMenu';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DEFAULT_COUNTRY } from '@cont/OfflineCentres/constants';
import { getEnquiryFormEndpoint } from '@cont/OfflineCentres/apiHelper';
import Button from 'aquilla/core/molecules/Button';
import Image from '@base/FutureImage';
import pushRoute from '@utils/pushRoute';
import useMobileLoginCountries from '@api/hooks/useMobileLoginCountries';
import useCreditsControl from '@api/hooks/credits/useCreditsControl';
import {
  LOGIN_SIGNUP_FIELD_CLICKED,
  ONBOARDING_START_LEARNING_CLICKED
} from './constants/event';

const formState = {
  phone: ''
};

const Container = styled.div`
  width: 1136px;
  margin: auto;
  padding: ${Spacings.SPACING_16B} 0;
  display: flex;
  flex-direction: row;
  ${mediaQuery.uptoMobile} {
    width: 100%;
    flex-direction: column;
    padding: 0;
    gap: ${Spacings.SPACING_4B};
  }
`;

const LeftWrapper = styled.div`
  width: 576px;
  display: flex;
  align-items: center;
  ${mediaQuery.uptoMobile} {
    width: 100%;
    padding-right: ${Spacings.SPACING_0B};
  }
`;

const WebImageWrapper = styled.div`
  flex: 1;
  margin-top: ${Spacings.SPACING_10B};
  display: block;
  ${mediaQuery.uptoMobile} {
    display: none;
  }
`;

const LoginBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${Spacings.SPACING_10B} ${Spacings.SPACING_0B} ${Spacings.SPACING_0B};
  gap: 24px;
  ${mediaQuery.uptoMobile} {
    width: 100%;
    padding-top: ${Spacings.SPACING_0B};
    padding-right: ${Spacings.SPACING_0B};
    align-items: center;
    gap: ${Spacings.SPACING_0B};
  }
`;

const PhoneNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacings.SPACING_2B};
  input {
    padding-left: ${Spacings.SPACING_0B};
  }
`;

const MobileLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacings.SPACING_6B};
  ${mediaQuery.uptoMobile} {
    width: 100%;
    padding: ${Spacings.SPACING_0B} ${Spacings.SPACING_4B};
  }
`;

const JoinforFree = styled(Button)`
  width: 464px;
  height: 48px;
  ${mediaQuery.uptoMobile} {
    width: 100%;
  }
`;

const StartLeanerning = styled(Button)`
  width: 273px;
  height: 48px;
  ${mediaQuery.uptoMobile} {
    width: 100%;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacings.SPACING_3B};
  ${mediaQuery.uptoMobile} {
    gap: ${Spacings.SPACING_3B};
    padding: ${Spacings.SPACING_0B} ${Spacings.SPACING_4B};
  }
`;

const StyledNumberField = styled(NumberField)`
  width: 100%;
  font-size: ${Fonts.H6};
  line-height: 50%;
  &.MuiOutlinedInput-root {
    width: 100%;
    height: ${Spacings.SPACING_12B};
    border-color: var(--color-text-primary);
    .MuiOutlinedInput-input {
      width: 100%;
      padding: ${Spacings.SPACING_2B} 0px;
    }
  }
`;

const CreditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacings.SPACING_4B};
`;
const SubHeaderContainer = styled.div`
  ${mediaQuery.uptoMobile} {
    * {
      font-size: ${Fonts.SMALL};
      line-height: ${Spacings.SPACING_5B};
    }
  }
`;

const SubHeader = () => {
  return (
    <SubHeaderContainer>
      <Typography variant="h5">
        Over{' '}
        <Typography as="span" variant="h5" color="#08BD80">
          10 crore
        </Typography>{' '}
        learners trust us for their preparation
      </Typography>
    </SubHeaderContainer>
  );
};

const MwebImageWrapper = styled.div`
  display: none;
  ${mediaQuery.uptoMobile} {
    padding: ${Spacings.SPACING_12B} ${Spacings.SPACING_0B};
    display: flex;
    justify-content: center;
  }
`;

const SignupCrediteWrapper = styled.div`
  position: relative;
  align-items: center;
  gap: ${Spacings.SPACING_1B};
  display: flex;
  flex-direction: row;
`;

const StyledTypography = styled(Typography)`
  font-weight: ${Fonts.HEAVY};
`;

const Message = styled(Typography)`
  color: ${({ $hasError }) =>
    $hasError ? '#EB5757' : 'var(--color-text-primary-light)'};
`;

const getDefaultCountry = (countries) => {
  return countries.length
    ? countries.find((c) => c.code === DEFAULT_COUNTRY.code)
    : DEFAULT_COUNTRY;
};

const validMobileNumber = (number, country) => {
  const errorMessage = 'Enter a valid mobile number';
  const mobileNumber = number?.toString();
  if (
    mobileNumber?.match(/^\d+$/) &&
    country?.code === 'IN' &&
    mobileNumber.length !== 10
  ) {
    return {
      error: true,
      message: errorMessage
    };
  }
  if (mobileNumber === '') {
    return {
      error: true,
      message: errorMessage
    };
  }
  return {
    error: false,
    message: null
  };
};

const Header = ({ sendEvent }) => {
  const setLocalPhoneNumber = useLoginStore(getSetLocalPhoneNumber);
  const { openElement: openLoginDrawer } = useToggleable(LOGIN_DRAWER);
  const countries = useMobileLoginCountries();
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const [payload, setPayload] = useState(null);
  const [errorObj, setErrorObj] = useState({});
  const defaultPhoneNumber = useMyInfo(getMePhone);
  const [defaultFormState, setDefaultFormState] = useState(formState);
  const [country, setCountry] = useState(getDefaultCountry(countries));
  const countryRef = useRef(getDefaultCountry(countries));
  const { registrar, onFormSubmit } = useForm({
    data: defaultFormState
  });
  const { isCreditsEnabledOnPlatform } = useCreditsControl();

  useEffect(() => {
    const formStateWithNumber = {
      ...defaultFormState,
      phone: defaultPhoneNumber
    };
    setDefaultFormState(formStateWithNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPhoneNumber]);

  const onSubmit = (data) => {
    const error = validMobileNumber(data.phone, countryRef?.current);
    if (error.error) {
      setErrorObj(error);
      return null;
    }
    setPayload({
      phone: data.phone
    });

    sendEvent({
      name: LOGIN_SIGNUP_FIELD_CLICKED,
      eventProperties: {
        last_primary_source: 'Unacademy Home',
        last_primary_source_section: 'Main section'
      }
    });

    if (!isLoggedIn) {
      setLocalPhoneNumber(String(data.phone), DEFAULT_COUNTRY);
      openLoginDrawer();
      setErrorObj({
        error: false,
        message: null
      });
    }
  };

  const changeCountry = (countryOption) => {
    const selectedCountry = countries.find(
      (c) => c.code === countryOption.value
    );
    countryRef.current = selectedCountry;
    setCountry(selectedCountry);
  };

  const submitEnquiryData = useCallback(() => {
    fetch(getEnquiryFormEndpoint(), {
      requestType: 'POST',
      body: { data: payload }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    if (isLoggedIn && payload) {
      submitEnquiryData();
    }
  }, [isLoggedIn, payload, submitEnquiryData]);

  const handleOnSubmit = onFormSubmit(onSubmit);

  const handleStartLearningClick = () => {
    sendEvent({
      name: ONBOARDING_START_LEARNING_CLICKED,
      eventProperties: {
        last_primary_source: 'Unacademy Home',
        last_primary_source_section: 'Main section'
      }
    });
    pushRoute('/explore');
  };

  return (
    <Container>
      <LeftWrapper>
        <LoginBlock>
          <HeaderWrapper>
            <Typography variant="h1">
              Crack your goal with India’s top educators
            </Typography>
            <SubHeader />
          </HeaderWrapper>
          <MwebImageWrapper>
            <Image
              src="home-illustration.svg"
              alt="header"
              height={195}
              width={328}
              loading="eager"
              priority
            />
          </MwebImageWrapper>
          <MobileLoginWrapper>
            {!isLoggedIn && (
              <PhoneNumberWrapper>
                <Field
                  registrar={registrar}
                  name="phone"
                  rules={[isRequired('Mobile number is required')]}
                  render={({ onChange, value, hasError }) => (
                    <>
                      <StyledNumberField
                        fullWidth
                        startAdornment={
                          <InputAdornment>
                            <CountryMenu
                              changeCountry={changeCountry}
                              country={country}
                              countries={countries}
                              disabled={isLoggedIn}
                            />
                          </InputAdornment>
                        }
                        placeholder="Enter your mobile number"
                        onChange={(_, data) => onChange(data)}
                        value={value}
                        inputProps={{
                          type: 'tel',
                          maxLength: country?.code === 'IN' ? 10 : 15,
                          minLength: 3,
                          required: true
                        }}
                        type="tel"
                        error={hasError}
                        disabled={isLoggedIn}
                      />
                      {!errorObj.error && (
                        <Message variant="p2" $hasError={hasError}>
                          {hasError
                            ? 'Enter a valid mobile number'
                            : 'We’ll send an OTP for verification'}
                        </Message>
                      )}
                      {errorObj.error && (
                        <Message variant="p2" $hasError>
                          {errorObj.message}
                        </Message>
                      )}
                    </>
                  )}
                />
              </PhoneNumberWrapper>
            )}
            <CreditWrapper>
              {!isLoggedIn && (
                <JoinforFree
                  variant="primary"
                  onClick={handleOnSubmit}
                  label="Join for free"
                />
              )}
              {isLoggedIn && (
                <StartLeanerning
                  variant="primary"
                  onClick={handleStartLearningClick}
                  label="Start learning"
                />
              )}
              {isCreditsEnabledOnPlatform && (
                <SignupCrediteWrapper>
                  <StyledTypography
                    variant="p3"
                    color="var(--color-i-orange-22)"
                  >
                    GET 100 CREDITS ON SIGN UP
                  </StyledTypography>
                  <Image
                    src="stars_home.svg"
                    width={12}
                    height={12}
                    alt="feature"
                  />
                </SignupCrediteWrapper>
              )}
            </CreditWrapper>
          </MobileLoginWrapper>
        </LoginBlock>
      </LeftWrapper>
      <WebImageWrapper>
        <Image
          src="home-illustration.svg"
          alt="header"
          height={333}
          width={560}
          loading="eager"
          priority
        />
      </WebImageWrapper>
    </Container>
  );
};

export default Header;
