/* eslint-disable no-plusplus */
import merge from 'lodash/merge';

const tableIndex = {
  user: 'users',
  author: 'users',
  course: 'courses',
  item: 'items',
  topology: 'topologies',
  feedrow: 'feedrows',
  question: 'questions',
  batch: 'batches',
  doubt: 'doubts',
  doubtSolution: 'doubtSolutions',
  template: 'templates',
  batchGroup: 'batchGroups'
};

const fetchDataFromNormalizer = (data, state) => {
  let newData = data;
  if (data && typeof data === 'object' && Object.keys(data)) {
    const dataKeys = Object.keys(data);
    if (dataKeys.length > 0) {
      let i = 0;
      while (i < dataKeys.length) {
        if (
          dataKeys[i] in tableIndex &&
          typeof newData[dataKeys[i]] !== 'object'
        ) {
          const stateData =
            state[tableIndex[dataKeys[i]]].data[newData[dataKeys[i]]];

          if (dataKeys.length > 1) {
            newData = merge(newData, {
              [dataKeys[i]]: fetchDataFromNormalizer(stateData, state)
            });
          } else {
            newData = fetchDataFromNormalizer(stateData, state);
          }
        } else {
          newData = merge(newData, {
            [dataKeys[i]]: fetchDataFromNormalizer(newData[dataKeys[i]], state)
          });
        }
        i += 1;
      }
    }
  }
  return newData;
};

export default fetchDataFromNormalizer;
