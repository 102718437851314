import useFetch from '@hooks/useFetch';
import { HOST_TYPE } from '@utils/api-helper/host';

const useOfflineCentreBannerData = (
  options = {
    fallbackData: undefined,
    pageType: null,
    itemUID: null
  }
) => {
  const key = `api/offline-centre-banners/${options.pageType}/${options.itemUID}`;
  const {
    data: offlineCentreBanner,
    isValidating,
    ...rest
  } = useFetch(
    key,
    { hostType: HOST_TYPE.CMS },
    { fallbackData: options.fallbackData, revalidateOnMount: true }
  );
  return {
    key,
    offlineCentreBanner,
    isValidating,
    ...rest
  };
};

export default useOfflineCentreBannerData;
