import styled from '@emotion/styled';
import Typography from 'aquilla/core/atoms/Typography';
import Link from '@base/Link/Link';
import { mediaQuery, Spacings, Fonts } from '@styles/index';
import { segment } from '@actions/analytics';
import { getIsLoggedIn } from '@api/selectors/auth';
import useRootPageData from '@api/hooks/home/useRootPageData';
import { ONBOARDING_START_LEARNING_CLICKED } from '@cont/Onboarding/constants';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { LOGIN_DRAWER } from '@constants/toggleables';
import { useToggleable } from '@stores/toggleables';
import { useEffect, useState } from 'react';
import pushRoute from '@utils/pushRoute';
import StartLearning from './StartLearning';
import StatsCard from './StatsCard';
import { LOGIN_SIGNUP_FIELD_CLICKED } from './constants/event';

const SectionWrapper = styled.section`
  display: flex;
  grid-column: span 24;
  justify-content: space-between;
  margin-top: 160px;
  align-items: center;
  ${mediaQuery.uptoTablet} {
    margin-top: 96px;
  }
  ${mediaQuery.uptoMobile} {
    margin-top: 64px;
    text-align: center;
    padding: 0 16px;
  }
`;

const Label = styled(Typography)`
  font-size: 40px;
  line-height: 120%;
  color: var(--color-i-black);
  font-weight: bold;
  ${mediaQuery.uptoTablet} {
    font-size: 28px;
  }
  ${mediaQuery.uptoMobile} {
    font-size: 22px;
  }
`;

const SecLabel = styled(Typography)`
  margin-top: ${Spacings.SPACING_4B};
  font-size: 20px;
  font-weight: normal;
  width: 364px;
  ${mediaQuery.uptoTablet} {
    width: auto;
    margin-top: 12px;
  }
  ${mediaQuery.uptoMobile} {
    width: 100%;
    font-size: 16px;
    line-height: 150%;
    margin-top: 12px;
    padding: 0 16px;
  }
`;

const TextContainer = styled.div`
  width: 465px;
  ${mediaQuery.uptoMobile} {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  height: 586px;
  align-items: center;
  ${mediaQuery.uptoTablet} {
    height: auto;
  }
  ${mediaQuery.uptoMobile} {
    display: none;
  }
`;

const Learning = styled(StartLearning)`
  margin-top: 48px;
  display: inline-block;
  font-size: ${Fonts.H4};
  padding: ${Spacings.SPACING_5B} ${Spacings.SPACING_8B};
  height: auto;
  ${mediaQuery.uptoTablet} {
    margin-top: 24px;
    height: 48px;
    font-size: 16px;
    padding: 0;
    width: 170px;
    line-height: 100%;
  }
`;

const Left = styled.div`
  display: grid;
  grid-template: repeat(${({ $length }) => $length}, 1fr) / 1fr;
  grid-row-gap: ${Spacings.SPACING_6B};
`;

const Right = styled(Left)`
  padding-left: 24px;
`;

const FourthSection = () => {
  const {
    rootPageData: { statsInfo = {}, stats = [] }
  } = useRootPageData();
  const { openElement: openLoginDrawer } = useToggleable(LOGIN_DRAWER);
  const [isStartLearning, setIsStartLearning] = useState(null);
  const isLoggedIn = useMyInfo(getIsLoggedIn);

  const statsHalfLength = Math.ceil(stats.length / 2);
  const leftStats = stats.slice(0, statsHalfLength);
  const rightStats = stats.slice(statsHalfLength);

  const onStartLearningClick = (e) => {
    if (e) e.preventDefault();
    segment.track(ONBOARDING_START_LEARNING_CLICKED, {
      last_primary_source_section: 'Default Banner',
      is_logged_in: isLoggedIn
    });
    if (!isLoggedIn) {
      segment.track(LOGIN_SIGNUP_FIELD_CLICKED, {
        last_primary_source: 'Unacademy Home',
        last_primary_source_section: 'Default banner'
      });
      openLoginDrawer();
      setIsStartLearning(true);
    } else {
      setIsStartLearning(false);
      pushRoute('/explore');
    }
  };

  useEffect(() => {
    if (isLoggedIn && isStartLearning) onStartLearningClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isStartLearning]);

  return (
    <SectionWrapper>
      <TextContainer>
        <Label variant="h2">{statsInfo.title}</Label>
        <SecLabel variant="h3">{statsInfo.subTitle}</SecLabel>
        <Link href="/explore" as="/explore">
          <Learning onClick={onStartLearningClick} />
        </Link>
      </TextContainer>
      <StatsContainer>
        <Left $length={leftStats.length}>
          {leftStats.map((stat) => (
            <StatsCard
              key={stat.title}
              label={stat.subTitle}
              text={stat.title}
              imageUrl={stat.urls?.default}
            />
          ))}
        </Left>
        <Right $length={rightStats.length}>
          {rightStats.map((stat) => (
            <StatsCard
              key={stat.title}
              label={stat.subTitle}
              text={stat.title}
              imageUrl={stat.urls?.default}
            />
          ))}
        </Right>
      </StatsContainer>
    </SectionWrapper>
  );
};

export default FourthSection;
