import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Button from 'aquilla/core/molecules/Button';

const LearningButton = styled(Button)``;

const StartLearning = ({ className, onClick }) => {
  return (
    <LearningButton
      className={className}
      label="Start learning"
      variant="brand"
      size="large"
      onClick={onClick}
    />
  );
};

export default StartLearning;

StartLearning.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

StartLearning.defaultProps = {
  className: '',
  onClick: () => {}
};
