const popularGoalsData = [
  {
    uid: 'KSCGY',
    name: 'UPSC CSE - GS',
    icon: 'home/goalIcons/upsc.svg',
    darkIcon: 'home/goalIcons/upsc_dark.svg'
  },
  {
    uid: 'TMUVD',
    name: 'IIT JEE',
    icon: 'home/goalIcons/iit_jee.svg',
    darkIcon: 'home/goalIcons/iit_jee_dark.svg'
  },
  {
    uid: 'YOTUH',
    name: 'NEET UG',
    icon: 'home/goalIcons/neet_ug.svg',
    darkIcon: 'home/goalIcons/neet_ug_dark.svg'
  },
  {
    uid: 'RTPSX',
    name: 'Bank exams',
    icon: 'home/goalIcons/bank_exams.svg',
    darkIcon: 'home/goalIcons/bank_exams_dark.svg'
  },
  {
    uid: 'BDNTN',
    name: 'SSC JE & state AE exams',
    icon: 'home/goalIcons/ssc.svg',
    darkIcon: 'home/goalIcons/ssc_dark.svg'
  },
  {
    uid: 'XNDUS',
    name: 'CAT & other MBA entrance tests',
    icon: 'home/goalIcons/cat.svg',
    darkIcon: 'home/goalIcons/cat_dark.svg'
  },
  {
    uid: 'PLWCX',
    name: 'CBSE class 12',
    icon: 'home/goalIcons/class_12.svg',
    darkIcon: 'home/goalIcons/class_12_dark.svg'
  },
  {
    uid: 'RXRWJ',
    name: 'CA Intermediate',
    icon: 'home/goalIcons/ca.svg',
    darkIcon: 'home/goalIcons/ca_dark.svg'
  }
];

export default popularGoalsData;
