/* eslint-disable max-lines */
import styled from '@emotion/styled';
import { mediaQuery, Spacings } from '@styles/index';
import Search, { SearchInput, SearchList } from 'aquilla/core/molecules/Search';
import { useEffect, useMemo, useRef, useState } from 'react';
import Close from 'aquilla/icons/actions/Close';
import Button from 'aquilla/core/molecules/Button';
import usePopularGoals from '@api/hooks/goal/usePopularGoals';
import useFollowedGoals from '@api/hooks/goal/useFollowedGoals';
import { getIsLoggedIn, getMe } from '@api/selectors/auth';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { hasActiveSubscriptionToGoal, getSubscription } from '@models/Me';
import useOnboardingGoals from '@api/hooks/home/useOnboardingGoals';
import useGoalSynonyms from '@api/hooks/home/useGoalSynonyms';
import { isValidGoalOption } from '@cont/ManageGoals/utils';
import { emptyList } from '@constants/empty';
import { goalLevelOnboardingStatus } from '@constants/index';
import { useUpdateSelectedGoal } from '@api/hooks/topology/useSelectedGoal';
import pushRoute from '@utils/pushRoute';
import { useSnackbar } from '@stores/snackbar';
import useWindowSize from '@hooks/useWindowSize';
import { setGoalLanguage } from '@api/fetchers/auth';
import { followGoal } from '@api/fetchers/goal/updaters';
import useGoalLanguages from '@api/hooks/goal/useGoalLanguages';
import Dynamic from '@base/Dynamic/Dynamic';
import { fetchGoalLanguages } from '@api/fetchers/home';
import { useToggleable } from '@stores/toggleables';
import { LOGIN_DRAWER } from '@constants/toggleables';
import uuid from '@utils/uuid';
import { segment } from '@actions/analytics';
import { getBusinessPlatform } from '@cont/BatchGroup/utils';
import {
  GOAL_SEARCH_RESULT_CLICKED,
  GOAL_SEARCH_SEARCH_CLICKED,
  LOGIN_SIGNUP_FIELD_CLICKED
} from './constants/event';
import PopularSearch from './PopularSearch';

const LanguageSelectionDrawer = Dynamic(() =>
  import('@comp/LanguageSelectionDrawer/LanguageSelectionDrawer')
);

const GoalSearchBarWrapper = styled.div`
  display: flex;
  position: sticky;
  z-index: 7;
  justify-content: center;
  margin-inline: auto;
  width: 1136px;
  top: ${Spacings.SPACING_2B};
  flex-direction: column;
  margin-bottom: ${Spacings.SPACING_4B};
  padding-top: ${Spacings.SPACING_4B};
  > div {
    transition: transform 200ms ease-out;
  }
  ${mediaQuery.uptoMobile} {
    display: flex;
    flex-direction: column;
    top: ${Spacings.SPACING_0B};
    width: 100%;
    background: var(--color-i-white);
    margin-bottom: ${Spacings.SPACING_0B};
    padding-top: ${Spacings.SPACING_3B};
    z-index: unset;
  }
`;
const Overlay = styled.div`
  display: ${({ show }) => (show ? 'block' : 'contents')};
  position: fixed; /* Stay in place */
  z-index: 5; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: var(--color-i-pitch-black); /* Black w/ opacity */
  opacity: 0.4;
  ${mediaQuery.uptoMobile} {
    display: contents;
  }
`;

const StyledSearchWrapper = styled(Search)`
  flex: 1;
  .aquilla-combobox {
    > div > div {
      height: 48px;
    }
    svg {
      color: var(--color-text-secondary);
    }
  }
  ${mediaQuery.uptoMobile} {
    .aquilla-combobox {
      width: calc(100% - 32px);
      margin: ${Spacings.SPACING_0B} auto;
    }
  }
`;

const StyledSearchListWrapper = styled(SearchList)`
  left: unset !important;
  max-height: 460px;
  padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_0B};
  overflow: auto;
  top: unset !important;
  margin-top: ${Spacings.SPACING_2B};
  border: ${Spacings.SPACING_1} solid var(--color-i-divider);
  border-radius: ${Spacings.SPACING_2B};
  z-index: 2;
  ${mediaQuery.uptoMobile} {
    margin-top: ${Spacings.SPACING_3B};
    width: 100% !important;
    left: 0 !important;
    height: 100vh;
    max-height: 100vh;
    border: unset;
    border-radius: ${Spacings.SPACING_0B};
  }
`;

const CloseCTA = styled(Button)`
  display: none;
  width: 45px;
  border: none;
  padding: ${Spacings.SPACING_0B};
  transition: width 1s ease-in-out;
  ${mediaQuery.uptoMobile} {
    display: inline-block;
    line-height: unset;
  }
`;
const CloseCTAWrapper = styled.div`
  width: 100%;
  display: none;
  justify-content: flex-start;
  margin-bottom: ${Spacings.SPACING_3B};
  padding-left: ${Spacings.SPACING_1B};
`;

const getFormattedGoals = (goals = []) => {
  return [
    {
      name: 'Popular goals',
      uid: 'Popular goals',
      children: goals || [],
      disable: true
    },
    ...goals
  ]?.map((goal) => ({
    ...goal,
    label: goal.name,
    value: goal.uid
  }));
};
const getMappedFollowedGoals = (followedGoals = []) => {
  return followedGoals.reduce((mappedFollowedGoals, followedGoal) => {
    return { ...mappedFollowedGoals, [followedGoal.uid]: followedGoal };
  }, {});
};

const GoalSearch = () => {
  const [showOverlay, setOverlay] = useState(false);
  const { isMobile } = useWindowSize();
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const { openElement: openLoginDrawer } = useToggleable(LOGIN_DRAWER);
  const { goalGroups } = useOnboardingGoals();
  const { popularGoals } = usePopularGoals();
  const [goalSearchValue, setGoalSearchValue] = useState('');
  const [filteredGoalOptions, setFilteredGoalOptions] = useState(goalGroups);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [drawerState, setDrawerState] = useState(false);
  const [isRequestingGoalSelection, setIsRequestingGoalSelection] =
    useState(false);
  const [currentSelectedData, setCurrentSelectedData] = useState(null);
  const { languages } = useGoalLanguages(selectedGoal?.uid);
  const meInfo = useMyInfo(getMe);
  const { latestUserSubscriptions } = meInfo || {};
  const { open: showSnackbar } = useSnackbar();
  const tapID = useRef(0);
  const goalSearchRef = useRef(null);
  const showError = ({ res }) => {
    showSnackbar({
      message:
        res.error.message ||
        'Some error occurred. Please refresh the page and try again.',
      type: 'error'
    });
  };

  useEffect(() => {
    setFilteredGoalOptions(goalGroups);
  }, [goalGroups]);

  const { followedGoals, mutate: fetchFollowedGoals } = useFollowedGoals({
    revalidateOnMount: true,
    fallbackData: emptyList
  });
  const mappedFollowedGoals = useMemo(
    () => getMappedFollowedGoals(followedGoals),
    [followedGoals]
  );
  const { goalSynonyms } = useGoalSynonyms();

  const formattedGoals = useMemo(
    () => getFormattedGoals(popularGoals),
    [popularGoals]
  );

  const createSetSelectedGoalAction = useUpdateSelectedGoal();
  const gotoSelectedGoal = (goal, routeObj) => {
    const route = routeObj || {
      href: '/goal/[goalSlug]/[goalUID]',
      as: `/goal/${goal.slug}/${goal.uid}`
    };
    createSetSelectedGoalAction(goal.uid);
    pushRoute(route.href, route.as);
  };

  const goToGloPage = (goal) => {
    gotoSelectedGoal(goal, {
      href: '/goal/[goalSlug]/[goalUID]/preferences',
      as: `/goal/${goal.slug}/${goal.uid}/preferences`
    });
  };

  const onLanguageSelect = async (languageCode, languageText, goal) => {
    const goalData = selectedGoal || goal;

    await followGoal(goalData.uid, true);
    await fetchFollowedGoals();

    const setGoalLanguageResponse = await setGoalLanguage(
      goalData.uid,
      languageCode
    );
    if (!setGoalLanguageResponse?.error?.error_code) {
      const selectedGoalExtraData = {
        ...goalData,
        relativeLink: goalData.relative_link,
        label: goalData.name,
        value: goalData.uid,
        as: goalData.relative_link,
        href: '/goal/[goalSlug]/[goalUID]'
      };
      setDrawerState(false);
      setSelectedGoal(null);
      gotoSelectedGoal(selectedGoalExtraData);
    }
  };
  const addToMyGoals = async (goal, gloData) => {
    if (gloData.isAvailable && isLoggedIn) {
      const pinResponse = await followGoal(goal.uid, true);
      if (pinResponse?.error) {
        showError({ res: pinResponse });
        return;
      }
      const followedGaolResponse = await fetchFollowedGoals();
      if (followedGaolResponse?.error) {
        showError({ res: followedGaolResponse });
        return;
      }

      if (
        gloData.status !== goalLevelOnboardingStatus.COMPLETE &&
        !isMobile &&
        !goal.isUaIconGoal
      ) {
        goToGloPage(goal);
      }
    } else if (isLoggedIn) {
      if (goal.isUaIconGoal) {
        gotoSelectedGoal(goal);
        return;
      }
      const res = await fetchGoalLanguages(goal.uid);
      if (res?.error) {
        showError({ res });
        return;
      }
      // when language is only 'All'
      if (res?.length === 1 && res[0]?.code === 0) {
        setSelectedGoal(goal);
        setTimeout(() => onLanguageSelect(0, 'All', goal), 1000);
        return;
      }
      if (!goal.isUaIconGoal) {
        setSelectedGoal(goal);
        setDrawerState(true);
      }
    } else {
      gotoSelectedGoal(goal);
    }
  };

  const handleGoalSelect = async ({ goal, isFollowedGoal }) => {
    setIsRequestingGoalSelection(false);
    setCurrentSelectedData(null);
    const isSubscribedGoal = hasActiveSubscriptionToGoal(meInfo, goal.uid);
    let isGoalFollowedByUser = isFollowedGoal;
    if (!isGoalFollowedByUser) {
      const followedGaolResponse = await fetchFollowedGoals();
      isGoalFollowedByUser =
        getMappedFollowedGoals(followedGaolResponse)?.[goal.uid];
    }
    const gloData = goal.goalLevelOnboarding || {
      isAvailable: goal.isGoalOnboardingAvailable || false,
      status: goal.userOnboardingStatus || -1
    };
    if (isGoalFollowedByUser || isSubscribedGoal) {
      if (
        gloData.isAvailable &&
        gloData.status !== goalLevelOnboardingStatus.COMPLETE &&
        !goal.isUaIconGoal
      )
        goToGloPage(goal);
      else {
        const goalRouteObj = {
          href: '/goal/[goalSlug]/[goalUID]',
          as: `/goal/${goal.slug}/${goal.uid}`
        };
        if (isSubscribedGoal && !goal.isUaIconGoal) {
          goalRouteObj.href += '/planner';
          goalRouteObj.as += '/planner';
        }
        gotoSelectedGoal(goal, goalRouteObj);
      }
    } else addToMyGoals(goal, gloData);
  };

  const ddOptions = useMemo(() => {
    const options = [];
    filteredGoalOptions.forEach((goalGroup) => {
      if (goalGroup.name) {
        options.push({
          ...goalGroup,
          value: goalGroup.uid,
          label: goalGroup.name,
          disable: true
        });
        goalGroup.children.forEach((goal) => {
          options.push({
            ...goal,
            value: goal.uid,
            label: goal.name
          });
        });
      }
    });
    return options;
  }, [filteredGoalOptions]);

  const handleScrollingBehavior = (isScrolling = false) => {
    const closeCTAWrapper = document.getElementById('search-closeCTAWrapper');
    if (isMobile && closeCTAWrapper) {
      closeCTAWrapper.style.display = isScrolling ? 'block' : 'none';
    }
    document.body.style.overflow = isScrolling ? 'hidden' : 'auto';
  };

  const handleCloseSearch = () => {
    setOverlay(false);
    setFilteredGoalOptions(goalGroups);
    handleScrollingBehavior(false);
  };

  const filterOptions = (value) => {
    if (!value) {
      return goalGroups;
    }
    const newGroupData = [];
    goalGroups.forEach((goalGroup) => {
      let goalGroupMatched = false;
      if (goalGroup.children.length) {
        if (isValidGoalOption(value, goalGroup, goalSynonyms)) {
          goalGroupMatched = true;
        }
        const goals = [];
        goalGroup.children.forEach((goal) => {
          if (isValidGoalOption(value, goal, goalSynonyms)) {
            goals.push(goal);
          }
        });
        if (goals.length) {
          newGroupData.push({ ...goalGroup });
          newGroupData[newGroupData.length - 1].children = goals;
        } else if (goalGroupMatched) {
          newGroupData.push(goalGroup);
        }
      }
    });
    return newGroupData;
  };
  const handleSearchValue = (value) => {
    const filteredOptions = filterOptions(value);
    setFilteredGoalOptions(filteredOptions);
    setGoalSearchValue(value);
  };

  const handleOnClear = () => {
    setGoalSearchValue('');
  };

  const handleSelect = (value) => {
    setGoalSearchValue(value);
  };

  const handleOpenSearch = () => {
    setOverlay(true);
    tapID.current = uuid();
    segment.track(GOAL_SEARCH_SEARCH_CLICKED, {
      last_primary_source_section: 'Goal search',
      tap_id: tapID.current
    });
    handleScrollingBehavior(true);
    const goalSectionContainer = document.getElementById('goal-selection');
    if (!isMobile && goalSectionContainer) {
      goalSectionContainer.scrollIntoView(true);
    } else if (isMobile && goalSearchRef) {
      goalSearchRef.current.scrollIntoView(true);
    }
    handleSearchValue(goalSearchValue);
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchFollowedGoals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const onGoalCardClick = (data) => {
    const { goal } = data;
    const { subscription, expiredAt, timeRemaining } =
      getSubscription(latestUserSubscriptions, goal.uid) || {};
    segment.track(GOAL_SEARCH_RESULT_CLICKED, {
      goal_name: goal.name,
      goal_uid: goal.uid,
      last_primary_source_section: 'Goal search',
      is_subscription_active: timeRemaining > 0,
      result_section_clicked: goal.name,
      search_stiring_entered: goalSearchValue,
      search_term: goalSearchValue,
      search_term_length: goalSearchValue?.length,
      subscription_duration: subscription?.duration || 'NA',
      subscription_end_date: expiredAt || 'NA',
      tap_id: tapID.current,
      business_platform: getBusinessPlatform(goal)
    });
    if (!isLoggedIn) {
      setOverlay(false);
      openLoginDrawer();
      setIsRequestingGoalSelection(true);
      setCurrentSelectedData({ ...data });
      segment.track(LOGIN_SIGNUP_FIELD_CLICKED, {
        last_primary_source: 'Unacademy Home',
        last_primary_source_section: 'Goal search'
      });
      return null;
    }
    handleGoalSelect(data);
  };
  useEffect(() => {
    if (isRequestingGoalSelection && isLoggedIn && currentSelectedData) {
      handleGoalSelect(currentSelectedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isRequestingGoalSelection, currentSelectedData]);
  return (
    <>
      <Overlay show={showOverlay} />
      <GoalSearchBarWrapper id="search-wrapper" ref={goalSearchRef}>
        <CloseCTAWrapper id="search-closeCTAWrapper">
          <CloseCTA
            variant="secondary"
            isIconButton
            onClick={handleCloseSearch}
          >
            <Close size={24} />
          </CloseCTA>
        </CloseCTAWrapper>
        <StyledSearchWrapper
          onOpen={handleOpenSearch}
          onClose={handleCloseSearch}
          onSelect={handleSelect}
        >
          <SearchInput
            value={goalSearchValue}
            onChange={handleSearchValue}
            onClear={handleOnClear}
            placeholder="Type the goal / exam you’re preparing for"
            className="goal-search-input"
          />
          <StyledSearchListWrapper>
            <PopularSearch
              options={goalSearchValue ? ddOptions : formattedGoals}
              goalSearchValue={goalSearchValue}
              mappedFollowedGoals={mappedFollowedGoals}
              onGoalCardClick={onGoalCardClick}
            />
          </StyledSearchListWrapper>
        </StyledSearchWrapper>
      </GoalSearchBarWrapper>
      {drawerState && (
        <LanguageSelectionDrawer
          languages={languages}
          onLanguageSelect={onLanguageSelect}
          drawerState={drawerState}
          setShowLanguages={setDrawerState}
          hideCloseButton
          heading="Pick a goal Language"
          subHeading="Courses in the preferred language will be shown first"
        />
      )}
    </>
  );
};
export default GoalSearch;
