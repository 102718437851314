import Icon from './Icon';

const ChevronLeft = (props) => (
  <Icon
    {...props}
    path="M52.033 20.2c0 0.003 0 0.008 0 0.012 0 0.687-0.28 1.308-0.733 1.755l-18.234 18.234 18.233 18.233c0.49 0.458 0.796 1.108 0.796 1.829 0 1.381-1.119 2.5-2.5 2.5-0.721 0-1.371-0.306-1.828-0.794l-0.001-0.001-20-20c-0.452-0.452-0.731-1.077-0.731-1.767s0.279-1.314 0.731-1.767v0l20-20c0.452-0.452 1.077-0.731 1.767-0.731s1.314 0.279 1.767 0.731v0c0.453 0.447 0.733 1.068 0.733 1.755 0 0.004 0 0.008 0 0.012v-0.001z"
  />
);

export default ChevronLeft;
