import useFetch from '@hooks/useFetch';
import { HOST_TYPE } from '@utils/api-helper/host';

const useCreditsControl = () => {
  const key = 'api/general-experiments/credits_control';
  const { data, ...rest } = useFetch(key, {
    hostType: HOST_TYPE.CMS
  });
  const isCreditsEnabledOnPlatform =
    !!data?.experimentValue?.isCreditsEnabledOnPlatform;
  return { isCreditsEnabledOnPlatform, ...rest };
};

export default useCreditsControl;
