/* eslint-disable max-lines */
import {
  timeInMinsSecs,
  formatTime,
  getDateShort,
  getTimeFormatInShortFormWithoutSecs,
  getYear,
  compareDates,
  addTime
} from '@utils/dates';
import { EVALUATION_STATUS } from '@constants/quiz';
import { programmeTypes, sessionStates } from '@constants/index';
import { Spacings } from '@styles/index';
import isEmpty from '@utils/isEmpty';
import { scheduleItemTypes } from '@constants/schedule';
import { RECORDED_CLASS_VALUE } from '@educator/Container/CreatePlusCourse/constants';

const learnerWaitTimeForClassRecording = 3 * 3600 * 1000; // 3 hours
export const classCancelledMsg = 'Class was cancelled by the Educator';

export const getLatestSession = (session) => ({
  correctCount: session.correct_count,
  incorrectCount: session.incorrect_count,
  finishedAt: session.finished_at ? new Date(session.finished_at) : null,
  isLive: session.is_live,
  score: session.score,
  skippedCount: session.skipped_count,
  startTime: session.start_time,
  uid: session.uid
});

export const slides = (pdf) => ({
  withAnnotations: pdf.with_annotation || pdf.withAnnotation,
  withoutAnnotations: pdf.no_annotation || pdf.noAnnotation
});

const posts = ['post', scheduleItemTypes.CLASS];
export const isPost = (data) => {
  if (!data) return false;
  return posts.includes(data.type);
};

const quizzes = ['quiz', scheduleItemTypes.QUIZ];
export const isQuiz = (data) => {
  if (!data) return false;
  return quizzes.includes(data.type);
};

const getNow = () => {
  return new Date().getTime();
};

export const getState = (data) => data?.state || sessionStates.FUTURE;
export const getLiveAt = (data) =>
  data?.liveAt ? new Date(data.liveAt).getTime() : 0;
export const getTimeDifference = (data) => {
  const liveAt = getLiveAt(data);
  const now = getNow();
  return (liveAt - now) / 1000;
};

export const isRecorded = (data) => getState(data) === sessionStates.PAST;

export const classDidNotHappen = (
  {
    secondsBeforeLive = 0,
    totalQuestions = 0,
    finishedAt = 0,
    activityAt,
    liveAt,
    state,
    type
  },
  timeLimit = learnerWaitTimeForClassRecording
) => {
  const liveAtTime = getLiveAt({ liveAt });
  const lessonState = getState({ state });
  const now = getNow();
  // activityAt key is specific to planner.
  const startTime = activityAt || finishedAt;
  const waitTime = new Date(startTime).getTime() + timeLimit;
  const timePassed = now - liveAtTime;
  const isLesson = isPost({ type });

  if (
    isLesson &&
    lessonState === sessionStates.FUTURE &&
    secondsBeforeLive === 0 &&
    (now > waitTime || timePassed > timeLimit)
  )
    return true;
  if (!isLesson && now > liveAtTime && totalQuestions === 0) return true;
  return false;
};

// type should always be post
export const isCurrentlyLive = (data, skipTimeCheck) => {
  const state = getState(data);
  const diff = getTimeDifference(data);
  const isClassCancelled = classDidNotHappen(data);
  if (
    state === sessionStates.LIVE ||
    (state === sessionStates.FUTURE &&
      (!isClassCancelled || skipTimeCheck) &&
      (skipTimeCheck || data.secondsBeforeLive <= 900 || diff <= 900))
  )
    return true;
  return false;
};

export const isUpcoming = (data) => {
  if (isPost(data))
    return (
      !classDidNotHappen(data) && !isRecorded(data) && !isCurrentlyLive(data)
    );
  // for type quiz
  const diff = getTimeDifference(data);
  return diff > 0;
};

export const isPostClickable = (data) => !isUpcoming(data);

export const isCompletedWithNoVideoUrl = (data) => {
  return isPost(data) && isRecorded(data) && !data.videoUrl;
};

export const hasPDF = (data) => {
  return isRecorded(data) && data.slides;
};

export const getVideoUrlInternal = (data, isExternal) => {
  if (isCurrentlyLive(data, isExternal)) return data.liveClassUrl;
  if (isRecorded(data)) {
    return data.videoUrl || data.liveClassUrl;
  }
  return null;
};

export const getVideoUrl = (unLiveUrl, data, isExternal) => {
  const videoUrl = getVideoUrlInternal(data, isExternal);
  if (unLiveUrl === '') return videoUrl;
  if (
    videoUrl &&
    unLiveUrl &&
    unLiveUrl.split('/v')[1]?.split('/')[0] !==
      videoUrl.split('/v')[1]?.split('/')[0]
  )
    return videoUrl;
  return unLiveUrl;
};

export const isClassMissedByEducator = (data) => {
  let liveAt = getLiveAt(data);
  liveAt += data.lessonDuration * 60000;
  const currentDate = new Date().getTime();
  return (
    data.type === 'post' &&
    data.state === sessionStates.FUTURE &&
    liveAt < currentDate
  );
};

export const getPersonName = (person) =>
  person.name || `${person.firstName || ''} ${person.lastName || ''}`;

export const isRecordedSession = ({ courseType, classType }) => {
  return (
    courseType === programmeTypes.HYBRID_NON_OFFLINE &&
    classType === RECORDED_CLASS_VALUE
  );
};

export const isLessonLive = (lesson, timeLimit = 0) => {
  if (!classDidNotHappen(lesson, timeLimit) && isCurrentlyLive(lesson))
    return true;
  return false;
};

export const getLessonInfoString = (
  lesson,
  forPlus,
  isBatch,
  isEducatorSide = false,
  showLessonRank = true,
  courseType,
  isRecordedBatchLesson = false,
  isOnlyRecordedClassesPresent
) => {
  if (isRecordedBatchLesson) {
    let details = '';
    details = getPersonName(lesson.author);
    if (lesson.hasWatched) {
      details += ' • Completed';
      return details;
    }
    if (lesson.watchTime) {
      details += ' • Continue watching';
      return details;
    }
    if (isLessonLive(lesson)) {
      details += ` • Started at ${formatTime(
        lesson.startedAt || lesson.liveAt
      )}`;
      return details;
    }
    if (isUpcoming(lesson)) {
      details += ` • ${formatTime(lesson.startedAt || lesson.liveAt)}`;
      return details;
    }
    details += ` • ${getTimeFormatInShortFormWithoutSecs(
      lesson.videoDuration
    )}`;
    return details;
  }
  if (!forPlus) return timeInMinsSecs(lesson.lessonDuration);

  let infoString = showLessonRank
    ? `Lesson ${lesson.contentRank || lesson.rank}${Spacings.SPACING_2SPACES}•${
        Spacings.SPACING_2SPACES
      }`
    : '';

  const timeLimit = isEducatorSide
    ? 3600 * 1000
    : learnerWaitTimeForClassRecording;

  if (isRecordedSession({ courseType, classType: lesson.classType }))
    return `${infoString}${getTimeFormatInShortFormWithoutSecs(
      lesson.lessonDuration
    )}`;
  if (classDidNotHappen(lesson, timeLimit)) return classCancelledMsg;
  if (!lesson.liveAt) {
    return '';
  }
  if (lesson.isLive) {
    infoString += `Started at ${formatTime(lesson.startedAt || lesson.liveAt)}`;
    if (isBatch)
      return `${infoString}${Spacings.SPACING_2SPACES}•${
        Spacings.SPACING_2SPACES
      }${getPersonName(lesson.author)}`;
    return infoString;
  }
  if (lesson.state === sessionStates.PAST) {
    infoString += `${
      !isOnlyRecordedClassesPresent || isBatch
        ? `${getDateShort(lesson.liveAt)}${Spacings.SPACING_2SPACES}•`
        : ''
    }${Spacings.SPACING_2SPACES}${getTimeFormatInShortFormWithoutSecs(
      lesson.lessonDuration
    )}`;
  } else {
    infoString += `${formatTime(lesson.liveAt)} `;
  }
  if (isBatch)
    return `${infoString}${Spacings.SPACING_2SPACES}•${
      Spacings.SPACING_2SPACES
    }${getPersonName(lesson.author)}`;
  return infoString;
};

export const getSpecialInfoString = (lesson, withDuration = true) => {
  if (lesson.state === sessionStates.PAST) {
    let infoString = `${getDateShort(lesson.liveAt)}, ${getYear(
      lesson.liveAt
    )}`;
    if (withDuration)
      infoString += ` • ${getTimeFormatInShortFormWithoutSecs(
        lesson.lessonDuration || lesson.videoDuration
      )}`;
    return infoString;
  }
  if (lesson.state === sessionStates.LIVE)
    return `Started on ${formatTime(lesson.liveAt)}`;
  return `Starts on ${getDateShort(lesson.liveAt)}, ${getYear(
    lesson.liveAt
  )} • ${formatTime(lesson.liveAt)}`;
};

export const getQuizState = (lesson) => {
  const currentDate = new Date();
  if (
    new Date(lesson.liveAtString).getTime() <= currentDate.getTime() &&
    new Date(lesson.endsAt).getTime() >= currentDate.getTime()
  )
    return sessionStates.LIVE;
  const val = compareDates(lesson.startsAt, currentDate);
  if (val === -1) return sessionStates.FUTURE;
  if (val === 1) return sessionStates.PAST;
};

export const checkForEvaluationPending = (evaluationState) => {
  switch (evaluationState) {
    case EVALUATION_STATUS.COMPLETED:
      return false;

    default:
      return true;
  }
};

export const getQuizInfoString = (
  quiz,
  isTestSeries,
  isEducatorSide = false,
  showQuizNumber = true,
  isRecordedBatchLesson = false,
  isOnlyRecordedClassesPresent = false
) => {
  const { startsAt, contentRank, latestSession, totalScore } = quiz;
  const showDate = !isOnlyRecordedClassesPresent || isTestSeries;
  if (isRecordedBatchLesson) {
    let recordedInfoString = '';
    if (startsAt && getQuizState(quiz) === sessionStates.FUTURE) {
      recordedInfoString += `${formatTime(startsAt)} • `;
    }
    recordedInfoString += getTimeFormatInShortFormWithoutSecs(
      quiz.videoDuration
    );
    return recordedInfoString;
  }

  if (!startsAt) return '';
  if (classDidNotHappen(quiz))
    return `${
      isTestSeries ? 'Test' : 'Quiz'
    } was cancelled/rescheduled by the Educator`;

  if (
    quiz?.latest_session?.evaluation_state !== undefined &&
    checkForEvaluationPending(quiz?.latest_session?.evaluation_state)
  ) {
    return 'You will be notified as soon the result is available';
  }

  let infoString = showQuizNumber
    ? `${isTestSeries ? 'Test' : 'Quiz'} ${contentRank}${
        Spacings.SPACING_2SPACES
      }•${Spacings.SPACING_2SPACES}`
    : '';

  if (!isEducatorSide && latestSession) {
    if (!isEmpty(latestSession.score)) {
      infoString += `${latestSession.score}/${totalScore} marks${Spacings.SPACING_2SPACES}•${Spacings.SPACING_2SPACES}`;
    }
    if (showDate) {
      infoString += `${getDateShort(quiz.liveAtString)}`;
    }

    return infoString;
  }

  if (
    !isEducatorSide &&
    !latestSession &&
    getQuizState(quiz) === sessionStates.PAST
  ) {
    infoString += `Missed${Spacings.SPACING_2SPACES}•${
      Spacings.SPACING_2SPACES
    }${showDate ? getDateShort(quiz.liveAtString) : ''}`;
    return infoString;
  }

  infoString += `${formatTime(startsAt)}`;
  return infoString;
};

function itemModel(itemdata) {
  const data = {
    week: itemdata.week,
    type: itemdata.type ?? 'post',
    rank: itemdata.rank,
    contentRank: itemdata.content_type_item_rank,
    courseUID: itemdata.courseUID,
    courseSlug: itemdata.courseSlug,
    batchGroup: itemdata?.batch_group ?? null,
    topicGroups: itemdata.topic_groups
      ? itemdata.topic_groups.map((topicGroup) => {
          return { topology: topicGroup };
        })
      : []
  };
  if (data.type === 'live_class' || data.type === 'LiveClass') {
    data.type = 'post';
  }
  if (data.type === 'Test' || data.type === 'Quiz') {
    data.type = 'quiz';
  }
  const item = itemdata.properties ? itemdata.properties : itemdata;
  data.uid = item.uid;
  data.displayLanguage = item.language_display;
  data.name = item.name || item.title;
  data.attachments = item.attachments || {};
  data.description = item.description || null;
  data.permalink = item.permalink;
  data.relativeLink = item.relative_link;
  data.createdAt = item.created_at;
  data.slug = item.slug;
  data.language = item.language;
  data.thumbnail = item.thumbnail_v1 || item.thumbnail || item.video?.thumbnail;
  data.thumbnailV1 = item.thumbnail_v1 || data.thumbnail;
  data.isSpecial = item.is_special || item.programme?.is_special;
  data.classType = item.class_type;
  data.collectionName = item.collection_name;
  data.collection = item.collection;
  data.liveAt = item.live_at
    ? new Date(item.live_at)
    : new Date(item.start_time);
  data.liveAtString = item.live_at || item.start_time;
  data.liveAtTime = new Date(item.live_at || item.start_time).getTime();
  data.startedAt = item.started_at ? new Date(item.started_at) : null;
  data.author = item.author || null;
  data.startsAt = item.start_time || null;
  data.goalUid = item.goal_uid || item.programme?.goal?.uid || null;
  data.goalName = item.goal_name || item.programme?.goal?.name || null;
  data.programmeUid =
    item.programmeUid || item.programme?.uid || item.collection?.uid;
  data.queryID = item.queryID;
  data.requestID = item.requestID;
  data.index = item.index;
  if (item.programme || item.collection) {
    data.programme = item.programme
      ? { course: item.programme }
      : { course: item.collection };
  }
  if (!data.contentRank)
    data.contentRank = item.content_type_item_rank || item.rank;

  data.hasWatched = item.has_watched || item.watched || item.has_attempted;
  data.liveClassUrl = item.live_class_url;
  data.videoUrl = item.video_url || item.video?.url;
  data.duration = item.duration;
  data.lessonDuration =
    item.video_duration || item.video?.duration || item.duration;
  data.endsAt = addTime(data.liveAt, data.lessonDuration, 's');
  data.finishedAt = item.finished_at ? new Date(item.finished_at) : data.endsAt;
  data.embedUrl = item.video?.embed_url;
  data.cdnUrl = item.video?.cdn_url;
  data.videoType = item.video?.type;
  data.youtubeId = item.video?.youtube_id;
  data.key = item.distribution_key ? item.distribution_key : item.key || null;
  data.isPdfAvailable = item.video?.is_pdf_available;
  data.pdfUrl = item.video?.pdf_url;
  data.secondsBeforeLive = item.seconds_before_live;
  data.state = data.type === 'post' ? item.state : getQuizState(data);
  data.isLive =
    item.is_live || data.type === 'post'
      ? item.state === sessionStates.LIVE
      : data.secondsBeforeLive === 0 &&
        compareDates(new Date(), data.startsAt) === 0;
  if (data.type === 'quiz')
    data.isLive = getQuizState(data) === sessionStates.LIVE;
  data.isOffline = item.is_offline ?? false;
  data.isLiveStreamingEnabled = item.is_live_streaming_enabled ?? true;
  data.watchTime = item.watch_time;
  data.slides = item.slides_pdf ? slides(item.slides_pdf) : null;
  data.latestSession = item.latest_session
    ? getLatestSession(item.latest_session)
    : null;
  data.totalQuestions = item.num_questions;
  data.totalScore = item.total_score;
  data.upvotes = item.recommend_count;
  data.goal = { topology: item.goal };
  data.specialInfoString =
    data.type === 'post' || data.classType === 1
      ? getSpecialInfoString(data)
      : '';

  data.isBookmarked = item.is_bookmarked || item.bookmarked || false;
  data.ogImage = item.og_image || null;
  data.programmeNM = item.collection2;
  data.liveClassDetails = item.value?.live_class || item?.live_class2;
  data.liveLearnersCount = item.live_learners_count;
  data.totalWatchTime = item.total_watch_time;
  data.likePercentage = item.like_percentage;
  data.topology = item.topology ? { topology: item.topology } : null;
  // Tags value map
  // NA = 0  # Not Available
  // PANEL_DISCUSSION = 1
  // MUST_WATCH = 2
  data.tags = item.tags ?? [];
  data.isSaved = item.is_saved;
  data.contentState = item.content_state || null;
  // adding this bcoz using programme key is creating a loop (sometimes) while denormalizing
  // due to next_session key - happens when lesson/test in next_session is saved.
  data.course = item.course;
  data.attendance = item.attendance;
  data.notesCount = item.notesCount;
  data.practiceDpp = itemdata?.practice_dpp || itemdata?.value?.practice_dpp;
  data.classState = item?.class_state;
  return data;
}

export const getEntityData = (data) => {
  const item = itemModel(data);
  return item;
};

export const checkIfVersionMismatches = (unLiveUrl, data) => {
  const videoUrl = getVideoUrlInternal(data);
  if (
    videoUrl &&
    unLiveUrl &&
    unLiveUrl.split('/v')[1]?.split('/')[0] !==
      videoUrl.split('/v')[1]?.split('/')[0]
  )
    return true;
  return false;
};

export const getLink = (data) => {
  const { nextTrack, isSpecial } = data ?? {};
  if (!nextTrack) return data?.relativeLink ?? '';
  const { slug, uid, properties } = nextTrack;
  if (slug && uid) return `/class/${slug}/${uid}`;
  // if a course is special class and inside properties of nextTrack/nextSession uid and relativeLink is available then use class link instead of course link to save redirect from course to class
  if (isSpecial && properties?.uid && properties?.relativeLink)
    return properties.relativeLink;
  const { relativeLink } = data;
  return relativeLink ?? '';
};

export const getLinkPath = () => '/class/[slug]/[uid]';

export const getInfoString = (
  lesson,
  isTestSeries,
  forPlus,
  isBatch,
  isEducatorSide = false,
  showLessonRank = true,
  showQuizNumber = true,
  courseType = 1,
  isRecordedBatchLesson,
  isOnlyRecordedClassesPresent = false
) => {
  if (lesson.type === 'post')
    return getLessonInfoString(
      lesson,
      forPlus,
      isBatch,
      isEducatorSide,
      showLessonRank,
      courseType,
      isRecordedBatchLesson,
      isOnlyRecordedClassesPresent
    );
  if (lesson.type === 'quiz')
    return getQuizInfoString(
      lesson,
      isTestSeries,
      isEducatorSide,
      showQuizNumber,
      isRecordedBatchLesson,
      isOnlyRecordedClassesPresent
    );
};

export const getAsAndHrefOfLesson = (lesson) => {
  const { slug: lessonSlug, uid: lessonUID } = lesson;
  const asLink = `/lesson/${lessonSlug}/${lessonUID}`;
  const hrefLink = '/lesson/[lessonSlug]/[lessonUID]';

  return { asLink, hrefLink };
};

export const getItemRedirectURL = (lesson, forPlus) => {
  const { type, slug, uid } = lesson;

  if (!forPlus && type === 'quiz') {
    return { href: '/quiz/[quizSlug]/[quizUID]', as: `/quiz/${slug}/${uid}` };
  }

  if (!forPlus)
    return {
      href: '/lesson/[lessonSlug]/[lessonUID]',
      as: `/lesson/${slug}/${uid}`
    };
  if (type === 'post')
    return { href: '/class/[slug]/[uid]', as: `/class/${slug}/${uid}` };
  if (type === 'quiz')
    return { href: '/quiz/[quizSlug]/[quizUID]', as: `/quiz/${slug}/${uid}` };
  return { href: '/class/[slug]/[uid]', as: `/class/${slug}/${uid}` };
};

const item = {
  Item: itemModel,
  getEntityData,
  isUpcoming,
  isClassMissedByEducator,
  hasPDF,
  getVideoUrl,
  getLink,
  getLinkPath,
  getInfoString,
  getAsAndHrefOfLesson,
  isPostClickable,
  getSpecialInfoString,
  getItemRedirectURL,
  checkIfVersionMismatches,
  isCompletedWithNoVideoUrl,
  getQuizInfoString,
  isLessonLive,
  classDidNotHappen,
  isCurrentlyLive,
  isPost,
  isRecorded,
  getTimeDifference,
  isQuiz
};

export default item;
