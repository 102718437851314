/* eslint-disable max-lines */
import Image from '@base/FutureImage';
import styled from '@emotion/styled';
import { Fonts, mediaQuery, Spacings } from '@styles/index';
import { SearchOption } from 'aquilla/core/molecules/Search';
import ChevronRightPlanner from '@icon/Planner/ChevronRightPlanner';

import Typography from 'aquilla/core/atoms/Typography';

const NoResultFound = styled.div`
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--color-base-1);
  ${mediaQuery.uptoMobile} {
    height: 320px;
  }
`;

const NoGoalFound = styled(Typography)`
  margin-top: ${Spacings.SPACING_6B};
  margin-bottom: ${Spacings.SPACING_2B};
`;

const HighlightText = styled(Typography)`
  display: inline;
  color: var(--color-text-primary);
`;
const NoResultImage = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  display: inline-block;
`;

const StyleSearchOption = styled(SearchOption)`
  padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
`;
const DdItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${Spacings.SPACING_3B};
  color: var(--color-text-primary);
`;

const GoalGroupWrapper = styled.div`
  padding: ${Spacings.SPACING_0B} ${Spacings.SPACING_4B};
`;

const GoalGroupTitle = styled(Typography)`
  color: var(--color-text-secondary);
  padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_0B};
  ${mediaQuery.uptoMobile} {
    font-weight: ${Fonts.LIGHT};
    font-size: ${Fonts.ULTRA_SMALL};
    line-height: ${Spacings.SPACING_4B};
  }
`;

const DdItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const DdItemInfoTitle = styled.div`
  line-height: 24px;
  ${mediaQuery.uptoMobile} {
    font-weight: ${Fonts.SEMIBOLD};
    font-size: ${Fonts.SMALL};
    line-height: ${Spacings.SPACING_5B};
  }
  span {
    white-space: break-spaces;
  }
`;

const Highlight = styled.span`
  background-color: var(--color-i-brown-6);
`;

const DDItem = ({
  goal,
  goalSearchValue = '',
  onGoalCardClick,
  mappedFollowedGoals
}) => {
  const start = goal.name
    .toLowerCase()
    .indexOf(goalSearchValue.trim().toLowerCase());
  const end = start + goalSearchValue.trim().length;
  const isGroupGoal = !!goal?.children?.length;
  const isFollowedGoal = !!mappedFollowedGoals[goal?.uid];
  const handleItemClick = () => {
    if (typeof onGoalCardClick === 'function') {
      onGoalCardClick({
        isFollowedGoal,
        goal
      });
    }
  };

  if (isGroupGoal)
    return (
      <GoalGroupWrapper>
        <GoalGroupTitle variant="p2">{goal.name}</GoalGroupTitle>
      </GoalGroupWrapper>
    );

  return (
    <StyleSearchOption title={goal.name}>
      <DdItemWrapper
        $isGroupGoal={isGroupGoal}
        url={goal.icon}
        onClick={handleItemClick}
      >
        <span className="goal-icon">
          <Image src={goal.icon} alt="" width={24} height={24} />
        </span>
        <DdItemInfo>
          <DdItemInfoTitle>
            <span>{start >= 0 && goal.name.substr(0, start)}</span>
            <Highlight>
              {start >= 0 && goal.name.substr(start, end - start)}
            </Highlight>
            <span>{start >= 0 ? goal.name.substr(end) : goal.name}</span>
          </DdItemInfoTitle>
        </DdItemInfo>
        <ChevronRightPlanner
          color="var(--color-text-secondary)"
          width="24"
          height="24"
        />
      </DdItemWrapper>
    </StyleSearchOption>
  );
};
const NoOptionComponent = () => {
  return (
    <NoResultFound>
      <NoResultImage>
        <Image
          src="zero-result.svg"
          alt="No Result Found"
          width={80}
          height={80}
        />
      </NoResultImage>
      <NoGoalFound variant="h4">No goals found</NoGoalFound>
      <Typography variant="p2" color="var(--color-text-secondary)">
        Try searching for your target exam
      </Typography>
      <Typography variant="p2" color="var(--color-text-secondary)">
        e.g.<HighlightText variant="p2"> ‘bank exams’ </HighlightText>
        or<HighlightText variant="p2"> ‘cbse class 10’</HighlightText>
      </Typography>
    </NoResultFound>
  );
};

const PopularSearch = ({
  options = [],
  goalSearchValue,
  mappedFollowedGoals = {},
  onGoalCardClick
}) => {
  if (!options.length) return <NoOptionComponent />;
  return (
    <>
      {options?.map((option) => (
        <DDItem
          key={option.uid}
          goal={option}
          goalSearchValue={goalSearchValue}
          mappedFollowedGoals={mappedFollowedGoals}
          onGoalCardClick={onGoalCardClick}
        />
      ))}
    </>
  );
};

export default PopularSearch;
