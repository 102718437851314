import styled from '@emotion/styled';
import { Spacings, Fonts, mediaQuery } from '@styles/index';
import Image from '@base/Image';
import Typography from 'aquilla/core/atoms/Typography';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import Button from 'aquilla/core/molecules/Button';
import { segment } from '@actions/analytics';
import {
  OFFLINE_CENTRE_KNOW_MORE_CLICKED,
  OFFLINE_GOAL_UID,
  KNOW_MORE_FLOW_TYPE_UN_HOME_PAGE
} from '@cont/OfflineCentres/constants';
import pushRoute from '@utils/pushRoute';
import useConstants from '@api/hooks/useConstants';
import useSelectedGoal from '@api/hooks/topology/useSelectedGoal';

const CTAButton = styled(Button)`
  margin-top: ${Spacings.SPACING_3B};
  max-width: 176px;
  ${mediaQuery.uptoMobile} {
    width: 100%;
    margin-top: ${Spacings.SPACING_2B};
  }
`;

const TagImageWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  height: ${Spacings.SPACING_3B};
  width: ${Spacings.SPACING_3B};
  position: relative;
`;
const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: ${Spacings.SPACING_10B};

  background-color: var(--color-i-white);
  box-shadow: ${boxShadowGenerator({
    color: 'var(--color-divider-secondary)'
  })};
  border-radius: ${Spacings.SPACING_2B};
  ${mediaQuery.uptoMobile} {
    width: 100%;
    flex-direction: column;
    padding: ${Spacings.SPACING_4B};
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 1136px;
  margin: auto;
  margin-bottom: ${Spacings.SPACING_20B};
  margin-top: ${Spacings.SPACING_14B};
  box-shadow: ${boxShadowGenerator({
    color: 'var(--color-divider-secondary)'
  })};
  ${mediaQuery.uptoMobile} {
    width: 100%;
    flex-direction: column;
    padding: 0px ${Spacings.SPACING_4B};
    margin-bottom: ${Spacings.SPACING_12B};
    margin-top: ${Spacings.SPACING_8B};
  }
`;

const Heading = styled(Typography)`
  margin-bottom: ${Spacings.SPACING_4B};
  ${mediaQuery.uptoMobile} {
    margin-bottom: ${Spacings.SPACING_2B};
    font-size: ${Spacings.SPACING_6B};
  }
`;

const SubHeading = styled(Typography)`
  color: var(--color-text-secondary);
  font-weight: ${Fonts.LIGHT};
  margin-bottom: ${Spacings.SPACING_6B};
  ${mediaQuery.uptoMobile} {
    margin-bottom: ${Spacings.SPACING_4B};
  }
`;
const TagText = styled(Typography)`
  margin-right: ${Spacings.SPACING_1B};
  color: ${({ $color }) => $color || 'var(--color-text-primary);'};
  font-weight: ${Fonts.HEAVY};
  line-height: ${Fonts.BUTTON_LINE_HEIGHT};
`;

const IntroWrapper = styled.div`
  display: flex;
  margin-bottom: ${Spacings.SPACING_4B};
  align-items: center;
  ${mediaQuery.uptoMobile} {
    width: 100%;
    margin-bottom: ${Spacings.SPACING_2B};
  }
`;

const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SingFeatWrapper = styled.div`
  display: flex;
  margin-bottom: ${Spacings.SPACING_3B};
  ${mediaQuery.uptoMobile} {
    margin-bottom: ${Spacings.SPACING_2B};
  }
`;

const BannerImage = styled(Image)`
  border-radius: ${Spacings.SPACING_2B};
  object-fit: cover;
`;

const TagImage = styled(Image)``;
const FeatIcon = styled(Image)``;

const FeaturIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FeatText = styled(Typography)`
  margin-left: ${Spacings.SPACING_2B};
  font-weight: ${Fonts.LIGHT};
  ${mediaQuery.uptoMobile} {
    font-size: ${Spacings.SPACING_14};
  }
`;

const LeftDiv = styled.div`
  width: 433px;
  position: relative;
  margin-right: ${Spacings.SPACING_10B};
  ${mediaQuery.uptoMobile} {
    width: 100%;
    height: 130px;
    margin-bottom: ${Spacings.SPACING_4B};
    margin-right: 0px;
  }
`;

const RightDiv = styled.div`
  width: 433px;
  display: flex;
  flex-direction: column;
  ${mediaQuery.uptoMobile} {
    width: 100%;
  }
`;

const OfflineCentreBanner = ({ data }) => {
  const {
    ctaText,
    ctaUrl,
    heading,
    subHeading,
    features,
    imageUrl,
    tagIconUrl,
    tagText,
    tagTextColor,
    id
  } = data;

  const { constants } = useConstants();
  const { selectedGoal } = useSelectedGoal();

  const handleAllCentreRouting = () => {
    const eligibleGoals = ['TMUVD', 'YOTUH', 'TPSBK', 'XFVVT'];
    if (selectedGoal && eligibleGoals.includes(selectedGoal?.uid)) {
      pushRoute({
        pathname: `/all-centre/${selectedGoal?.uid}`
      });
    } else {
      pushRoute({
        pathname: '/all-centre'
      });
    }
  };

  if (!id) return null;
  const renderFeatures = () => {
    if (!features || features.length === 0) return null;
    const feature = features.map((feat) => {
      const { text, iconUrl } = feat.data || {};
      return (
        <SingFeatWrapper key={feat.id}>
          <FeaturIconWrapper>
            <FeatIcon
              src={iconUrl}
              height={Spacings.SPACING_5B}
              width={Spacings.SPACING_5B}
            />
          </FeaturIconWrapper>
          <FeatText variant="p1">{text}</FeatText>
        </SingFeatWrapper>
      );
    });

    return <FeaturesWrapper>{feature}</FeaturesWrapper>;
  };

  const onClickHandler = () => {
    segment.track(OFFLINE_CENTRE_KNOW_MORE_CLICKED, {
      know_more_flow_type: KNOW_MORE_FLOW_TYPE_UN_HOME_PAGE,
      goal_uid: OFFLINE_GOAL_UID
    });

    if (constants?.ENABLE_CENTRES_PAGE) {
      handleAllCentreRouting();
    } else {
      window.open(ctaUrl);
    }
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <LeftDiv>
          <BannerImage src={imageUrl} layout="fill" />
        </LeftDiv>
        <RightDiv>
          {tagText && (
            <IntroWrapper>
              <TagText variant="h6" $color={tagTextColor}>
                {tagText.toUpperCase()}
              </TagText>
              <TagImageWrapper>
                <TagImage src={tagIconUrl} layout="fill" />
              </TagImageWrapper>
            </IntroWrapper>
          )}
          {heading && <Heading variant="h2">{heading}</Heading>}
          {subHeading && (
            <SubHeading variant="h4" color="var(--color-text-secondary)">
              {subHeading}
            </SubHeading>
          )}
          {renderFeatures()}
          <CTAButton size="large" onClick={onClickHandler}>
            {ctaText}
          </CTAButton>
        </RightDiv>
      </InnerWrapper>
    </Wrapper>
  );
};

export default OfflineCentreBanner;
