import { itemTypes, programmeTypes } from '@constants/index';

export const SESSION_TAB_INDEX_MAPPINGS = {
  0: itemTypes.POST,
  1: itemTypes.QUIZ,
  2: itemTypes.POST
};

export const STATIC_DATE = '01/01/2099';

export const RECORDED_CLASS_VALUE = 2;
export const LIVE_CLASS_VALUE = 1;

export const classTypeOptions = [
  { label: 'Live', value: LIVE_CLASS_VALUE },
  { label: 'Recorded', value: RECORDED_CLASS_VALUE }
];

// this is for radio button
export const COURSE_TYPE_ONLINE = 'online';
export const COURSE_TYPE_OFFLINE = 'offline';
export const HYBRID_NON_OFFLINE = 'hybrid';

export const COMPUTER_BASED = 'computer-based';
export const PAPER_BASED = 'paper-based';
export const testModeOptions = { 'paper-based': 1, 'computer-based': 2 };

export const courseTypeOptions = [
  { value: COURSE_TYPE_ONLINE, label: 'Online only', width: '264px' },
  {
    value: COURSE_TYPE_OFFLINE,
    label: 'Hybrid (Unacademy centre)',
    width: '264px'
  },
  { value: HYBRID_NON_OFFLINE, label: 'Hybrid (non-offline)', width: '264px' }
];

export const PROGRAMME_TYPE_MAPPINGS = {
  [COURSE_TYPE_ONLINE]: programmeTypes.COURSE,
  [COURSE_TYPE_OFFLINE]: programmeTypes.HYBRID_COURSE,
  [HYBRID_NON_OFFLINE]: programmeTypes.HYBRID_NON_OFFLINE
};

export const EDUCATOR_TYPES = {
  UNACADEMY_EDUCATOR: 1,
  PARTNER_EDUCATOR: 2
};

export const EDUCATOR_TYPE_OPTIONS = [
  {
    id: EDUCATOR_TYPES.UNACADEMY_EDUCATOR,
    name: 'Unacademy educator'
  },
  {
    id: EDUCATOR_TYPES.PARTNER_EDUCATOR,
    name: 'Partner educator'
  }
];
