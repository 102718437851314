/* eslint-disable @next/next/no-img-element */
import { segment } from '@actions/analytics';
import styled from '@emotion/styled';
import { mediaQuery, Spacings } from '@styles/index';
import GoalSelection from './GoalSelection';
import Header from './Header';

const Wrapper = styled.div`
  width: 100%;
  ${mediaQuery.uptoMobile} {
    margin-top: ${Spacings.SPACING_8B};
    border-top: none;
  }
`;

const HomeContainer = () => {
  const sendEvent = ({ name, eventProperties = {} }) => {
    segment.track(name, {
      ...eventProperties
    });
  };

  return (
    <Wrapper>
      <Header sendEvent={sendEvent} />
      <GoalSelection sendEvent={sendEvent} />
    </Wrapper>
  );
};

export default HomeContainer;
