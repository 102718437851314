import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Typography from 'aquilla/core/atoms/Typography';
import CustomModal from '@comp/CustomModal/CustomModal';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import { Spacings, Fonts, breakpoints } from '@styles/index';
import NextImage from '@base/FutureImage';
import useOneLinkSmartScript from '@hooks/useOneLinkSmartScript';
import { EDUCATOR_PAGE, HOME_PAGE } from '@constants/appFlyerLinks';
import { useIsMobile } from '@stores/AppCommonData';

const SectionWrapper = styled.section`
  display: grid;
  grid-template: ${({ $isFloating }) =>
    $isFloating
      ? 'max-content max-content / 1fr'
      : '1fr / max-content max-content'};
  grid-template-areas: ${({ $isFloating }) =>
    $isFloating
      ? "'image-wrapper' 'content-wrapper'"
      : "'content-wrapper image-wrapper'"};
  border-radius: ${Spacings.SPACING_2B};
  background-color: var(--color-base-1);
  box-shadow: ${boxShadowGenerator()};
  width: ${({ $isFloating }) => ($isFloating ? '440px' : '1136px')};
  margin: auto;
  ${({ $isFloating }) =>
    $isFloating ? '' : 'margin-top: 160px; justify-content: space-between;'}
  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 728px;
    overflow: hidden;
    margin-top: 96px;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column-reverse;
    margin: 96px 16px 8px;
    box-shadow: 0px 8px 16px rgba(221, 230, 237, 0.4);
  }
`;

const Label = styled(Typography)`
  font-size: ${({ $isFloating }) => ($isFloating ? Fonts.H3 : '40px')};
  ${({ $isFloating }) => ($isFloating ? 'text-align: center;' : '')}
  line-height: ${Fonts.PARA_LINE_HEIGHT};
  @media only screen and (max-width: ${breakpoints.tablet}) {
    font-size: 22px;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    font-size: 28px;
  }
`;

const SecLabel = styled(Typography)`
  margin: ${({ $isFloating }) =>
    $isFloating
      ? `${Spacings.SPACING_2B} ${Spacings.SPACING_10B} 0px`
      : `${Spacings.SPACING_6B} 0px 0px`};
  font-weight: ${Fonts.REGULAR};
  ${({ $isFloating }) => ($isFloating ? `font-size: ${Fonts.H5}` : '')}
  @media only screen and (max-width: ${breakpoints.tablet}) {
    margin-top: ${Spacings.SPACING_3B};
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    margin-top: ${Spacings.SPACING_1B};
  }
`;

const TextWrapper = styled.div`
  padding: ${({ $isFloating }) =>
    $isFloating
      ? `${Spacings.SPACING_10B} 0 ${Spacings.SPACING_12B} 0`
      : `116px 0 ${Spacings.SPACING_20B} ${Spacings.SPACING_24B}`};
  width: ${({ $isFloating }) => ($isFloating ? '100%' : '465px')};
  border-radius: 0 0 ${Spacings.SPACING_2B} ${Spacings.SPACING_2B};
  grid-area: content-wrapper;
  ${({ $isFloating }) => ($isFloating ? 'text-align: center' : '')}
  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding: 58px 62px 54px;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding: 40px 16px;
    width: 100%;
    text-align: center;
  }
`;

const PhoneWrapper = styled.div`
  background-color: ${({ $isFloating }) =>
    $isFloating ? 'var(--color-base-1)' : 'var(--color-base-1)'};
  ${({ $isFloating }) =>
    $isFloating ? `padding-top: ${Spacings.SPACING_12B};` : ''}
  ${({ $isFloating }) => ($isFloating ? 'text-align: center;' : '')}
  margin: ${({ $isFloating }) =>
    $isFloating ? 'auto' : `${Spacings.SPACING_10B} 128px 0px 0px`};
  border-radius: ${Spacings.SPACING_2B} ${Spacings.SPACING_2B} 0 0;
  grid-area: image-wrapper;
  max-width: ${({ $isFloating }) => ($isFloating ? '380px' : '432px')};
  display: inherit;
  @media only screen and (max-width: ${breakpoints.tablet}) {
    display: flex;
    align-items: flex-end;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    margin: 0;
    display: flex;
    justify-content: center;
    padding: 40px 41px 0;
    background: linear-gradient(
      180deg,
      var(--color-i-white) 73.44%,
      var(--color-base-0) 100%
    );
  }
`;

const AppContainer = styled.div`
  display: flex;
  justify-content: ${({ $isFloating }) => ($isFloating ? 'center' : 'initial')};
  margin-top: ${({ $isFloating }) =>
    $isFloating ? Spacings.SPACING_7B : Spacings.SPACING_10B};
  ${({ $isFloating }) => ($isFloating ? 'text-align: center;' : '')}
  @media only screen and (max-width: ${breakpoints.tablet}) {
    margin-top: 32px;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    grid-column-gap: 12px;
  }
`;

const StoreImage = styled.div`
  margin-right: ${Spacings.SPACING_4B};
  height: ${({ $isFloating }) => ($isFloating ? '40px' : '48px')};
  position: relative;
  width: ${({ $isFloating }) => ($isFloating ? '133px' : '159px')};
  @media only screen and (max-width: ${breakpoints.tablet}) {
    height: 40px;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    height: 48px;
    margin: 0;
  }
  @media only screen and (max-width: 384px) {
    height: 40px;
    margin: 0;
  }
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const AppStoreImage = styled(StoreImage)``;

const PlayStoreImage = styled(StoreImage)``;

const Phone = styled(NextImage)`
  height: ${({ $isFloating }) => ($isFloating ? '289px' : '404px')};
  width: ${({ $isFloating }) => ($isFloating ? '273px' : '381px')};
  margin: ${({ $isFloating }) =>
    $isFloating ? 'auto' : `${Spacings.SPACING_10B} 128px 0px 0px`};
  ${({ $isFloating }) => ($isFloating ? 'display: block;' : '')}
  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 240px;
    height: 254px;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 244px;
    height: 259px;
    margin: 0;
  }
`;

const educatorDefaultProps = {
  title: 'All in one place',
  description:
    'Schedule your classes, engage with learners, see your performance across your courses',
  androidApp:
    'https://play.google.com/store/apps/details?id=com.unacademy&referrer=utm_source%3DFooter%26utm_medium%3DWebApp%26utm_campaign%3DFooter%26',
  iosApp:
    'https://apps.apple.com/in/app/unacademy-educator-app/id1156375099%26referrer%3Dutm_source%253DFooter%2526utm_medium%253DWebApp%2526utm_campaign%253DFooter%2526'
};

const learnerDefaultProps = {
  title: 'Get the learning app',
  description:
    'Download lessons and learn anytime, anywhere with the Unacademy app',
  androidApp:
    'https://play.google.com/store/apps/details?id=com.unacademyapp&referrer=utm_source%3DFooter%26utm_medium%3DWebApp%26utm_campaign%3DFooter%26',
  iosApp:
    'https://apps.apple.com/in/app/unacademy-learning-app/id1342565069?referrer%3Dutm_source%253DFooter%2526utm_medium%253DWebApp%2526utm_campaign%253DFooter%2526'
};

const Content = ({
  className,
  isFloating,
  dataProps,
  imageNode,
  defaultImageSrc
}) => {
  const { title, description, androidApp, iosApp } = dataProps;
  return (
    <SectionWrapper className={className} $isFloating={isFloating}>
      <TextWrapper $isFloating={isFloating} className="content">
        <Label $isFloating={isFloating} className="title" variant="h4">
          {title}
        </Label>
        <SecLabel
          color="var(--color-text-primary)"
          $isFloating={isFloating}
          className="description"
          variant="h4"
        >
          {description}
        </SecLabel>
        <AppContainer $isFloating={isFloating} className="app-links">
          <a target="_blank" rel="noopener noreferrer" href={iosApp}>
            <AppStoreImage $isFloating={isFloating}>
              <NextImage
                src="app_store.png"
                width={128}
                height={40}
                alt="appStore"
              />
            </AppStoreImage>
          </a>
          <a href={androidApp} target="_blank" rel="noopener noreferrer">
            <PlayStoreImage $isFloating={isFloating}>
              <NextImage
                src="play_store.png"
                width={128}
                height={40}
                alt="playStore"
              />
            </PlayStoreImage>
          </a>
        </AppContainer>
      </TextWrapper>
      {imageNode || (
        <PhoneWrapper $isFloating={isFloating} className="image">
          <Phone
            $isFloating={isFloating}
            src={defaultImageSrc}
            height={404}
            width={400}
            alt="phone"
          />
        </PhoneWrapper>
      )}
    </SectionWrapper>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  isFloating: PropTypes.bool,
  dataProps: PropTypes.objectOf(PropTypes.any).isRequired,
  imageNode: PropTypes.node,
  defaultImageSrc: PropTypes.objectOf(PropTypes.any).isRequired
};

Content.defaultProps = {
  className: '',
  isFloating: false,
  imageNode: null
};

const LearnOnTheGo = ({
  className,
  isFloating,
  modalState,
  onClose,
  isEducator,
  imageNode,
  learnerTitle,
  learnerDescription,
  learnerAppOneLink,
  educatorTitle,
  educatorDescription,
  educatorAppOneLink
}) => {
  let educatorAppLink = '';
  let learnerAppLink = '';

  const isMobile = useIsMobile();
  const parameterizedLearnerAppOneLink =
    useOneLinkSmartScript(learnerAppOneLink);
  if (isMobile) {
    educatorAppLink = educatorAppOneLink;
    learnerAppLink = parameterizedLearnerAppOneLink;
  }

  const dataProps = isEducator
    ? {
        title: educatorTitle,
        description: educatorDescription,
        androidApp: educatorAppLink || educatorDefaultProps.androidApp,
        iosApp: educatorAppLink || educatorDefaultProps.iosApp
      }
    : {
        title: learnerTitle,
        description: learnerDescription,
        androidApp: learnerAppLink || learnerDefaultProps.androidApp,
        iosApp: learnerAppLink || learnerDefaultProps.iosApp
      };

  const content = (
    <Content
      className={className}
      isFloating={isFloating}
      dataProps={dataProps}
      imageNode={imageNode}
      defaultImageSrc={isEducator ? 'educatorApp.png' : 'newApp.png'}
    />
  );
  if (isFloating) {
    return (
      <CustomModal
        overlayTransparent
        modalState={modalState}
        onClose={onClose}
        bottomFrom="56px"
        bottomTo="76px"
        gridColumn="6/15"
      >
        {content}
      </CustomModal>
    );
  }
  return content;
};

export default LearnOnTheGo;

LearnOnTheGo.propTypes = {
  className: PropTypes.string,
  isFloating: PropTypes.bool,
  modalState: PropTypes.bool,
  onClose: PropTypes.func,
  isEducator: PropTypes.bool,
  imageNode: PropTypes.node,
  learnerTitle: PropTypes.string,
  learnerDescription: PropTypes.string,
  learnerAppOneLink: PropTypes.string,
  educatorTitle: PropTypes.string,
  educatorDescription: PropTypes.string,
  educatorAppOneLink: PropTypes.string
};

LearnOnTheGo.defaultProps = {
  className: '',
  isFloating: false,
  modalState: false,
  onClose: () => {},
  isEducator: false,
  imageNode: null,
  learnerTitle: learnerDefaultProps.title,
  learnerDescription: learnerDefaultProps.description,
  learnerAppOneLink: HOME_PAGE,
  educatorTitle: educatorDefaultProps.title,
  educatorDescription: educatorDefaultProps.description,
  educatorAppOneLink: EDUCATOR_PAGE
};
