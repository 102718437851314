/* eslint-disable react/no-array-index-key */
import {
  Children,
  useState,
  forwardRef,
  useCallback,
  useEffect,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IconButton from '@base/IconButton/IconButton';
import H4 from '@base/H4/H4';
import Grow from '@base/Grow/Grow';
import Link from '@base/Link/Link';
import P1 from '@base/P1/P1';
import ChevronLeft from '@icon/ChevronLeft';
import ChevronRight from '@icon/ChevronRight';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import Button from '@base/Button/Button';
import useWindowSize from '@hooks/useWindowSize';
import { Spacings, Fonts, mediaQuery } from '@styles/index';
import pushRoute from '@utils/pushRoute';
import Typography from 'aquilla/core/atoms/Typography';

const StyH4 = styled(H4)`
  color: var(--color-text-primary);
`;

const BoldP1 = styled(P1)`
  font-weight: ${Fonts.BOLD};
`;

const ItemWrap = styled.div`
  grid-column: ${({ gridColumn }) => gridColumn};
  opacity: ${({ opacity }) => opacity};
  display: flex;
  transition: opacity 1000ms ease-in, transform 400ms ease-in !important;
  ${mediaQuery.uptoMobile} {
    opacity: 1;
    grid-row: 1;
    grid-column: span 1;
  }
`;

const CarouselWrapper = styled.div`
  grid-column: ${({ gridColumn }) => gridColumn};
  position: relative;
  width: calc(100% + ${Spacings.SPACING_8B});
  margin-left: -${Spacings.SPACING_4B};
  margin-right: -${Spacings.SPACING_4B};
  ${mediaQuery.uptoMobile} {
    margin-left: 0;
    margin-right: 0px;
    width: 100%;
    ${({ $css }) => $css}
    ${({ $hasSeeAll }) => ($hasSeeAll ? 'margin-bottom:0;' : '')}
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ $gridTemplateColumns }) =>
    `repeat(${$gridTemplateColumns}, 32px)`};
  grid-column-gap: ${({ $gridColumnGap }) =>
    $gridColumnGap || Spacings.SPACING_4B};
  overflow: hidden;
  padding-left: ${Spacings.SPACING_4B};
  padding-bottom: ${({ $showBottomGap }) =>
    $showBottomGap ? Spacings.SPACING_9B : '0px'};
  & > a,
  & > div {
    transform: ${({ pos }) =>
      (pos && `translate3d(calc(${-100 * pos}% - ${pos * 16}px), 0, 0)`) ||
      'initial'};
    transition: transform 400ms ease-in;
  }
  ${mediaQuery.uptoMobile} {
    padding: 0 ${Spacings.SPACING_4B} ${Spacings.SPACING_6B}
      ${Spacings.SPACING_4B};
    margin: 0 -16px;
    overflow: scroll;
    overscroll-behavior: unset;
    scroll-behavior: smooth;
    grid-template-columns: ${({ $numberOfItemsToShow, $nItems }) => `repeat(
      ${$nItems},
      minmax(${$numberOfItemsToShow === 1 ? '100%' : '250px'}, 1fr)
    )`};
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const ControlButton = styled(IconButton)`
  position: absolute;
  right: ${({ $isUnIconArrow }) => $isUnIconArrow && '-16px'} !important;
  ${mediaQuery.uptoIconTablet} {
    right: ${({ $isUnIconArrow }) => $isUnIconArrow && '0px'};
  }
  /* turning off for now as suggested by design */
  /* visibility: hidden; */
  /* opacity: 0; */
  transition: visibility 0s linear 300ms, opacity 300ms;
  top: calc(50% - 16px - ${({ $topAdjustment }) => $topAdjustment});
  box-shadow: ${boxShadowGenerator()};
  /* turning off for now as suggested by design */
  /* ${CarouselWrapper}:hover & {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
  } */
  ${({ direction }) => (direction === 'prev' ? 'left' : 'right')}: 0px;
  ${mediaQuery.uptoMobile} {
    display: none;
  }
`;

const CaraouselInfo = styled.div`
  display: flex;
  margin-bottom: ${Spacings.SPACING_4B};
  margin-top: ${Spacings.SPACING_7B};
  align-items: center;
  padding: 0;
  grid-column: span 18;
  @media (max-width: 70.95em) {
    margin: 0 0 24px;
  }
`;

const NavButtonsContainer = styled.div`
  grid-column: ${({ $gridColumn }) => $gridColumn};
  padding: ${Spacings.SPACING_2B};
  padding-bottom: ${Spacings.SPACING_2B};
  display: flex;
  justify-content: center;
  margin-top: 0px;
`;

const NavButton = styled.div`
  width: ${Spacings.SPACING_2B};
  height: ${Spacings.SPACING_2B};
  border-radius: 50%;
  background-color: ${({ $isSelected }) =>
    $isSelected ? 'var(--color-text-secondary)' : 'var(--color-divider)'};
  margin: 0 ${Spacings.SPACING_1B};
  cursor: pointer;
`;

const SeeAll = styled(Button)`
  display: none;
  ${mediaQuery.uptoMobile} {
    display: block;
    min-height: 40px;
    height: 40px;
    width: 100%;
    background: var(--color-i-white);
    border: 1px solid var(--color-divider);
    border-radius: 8px;
    color: var(--color-i-green);
    font-size: 14px;
    margin: 16px 0 56px;
  }
`;
const InfoSectionsDiv = styled.div``;

const Description = styled(Typography)`
  color: var(--color-text-secondary);
  margin-top: ${Spacings.SPACING_1B};
`;

const Carousel = forwardRef(
  (
    {
      children,
      label,
      seeAllLink,
      href,
      showControls,
      className,
      numberOfItems,
      minItems,
      gridColumn,
      itemGridColumn,
      gridColumnGap,
      showNavButtons,
      showLabel,
      viewRef,
      showInfo,
      showBottomGap,
      $css,
      autoScroll,
      autoScrollDelay,
      handleClickSeeAll,
      labelChild,
      isUnIconArrow,
      gridTemplateColumns,
      description
    },
    ref
  ) => {
    const cardContainerRef = useRef(null);
    const { isMobile } = useWindowSize();
    const items = Children.toArray(children);
    const [pos, setPos] = useState(0);
    const showNavigationButtons = showNavButtons && items.length > 1;

    const finalItems = items.map((item, index) => {
      const gridColumnForItem =
        itemGridColumn || item.props.gridColumn || 'span 6';
      const endPoint = pos + (numberOfItems - 1);
      const startPoint = pos;
      const opacity = index >= startPoint && index <= endPoint ? 1 : 0;
      return (
        <ItemWrap gridColumn={gridColumnForItem} opacity={opacity} key={index}>
          {item}
        </ItemWrap>
      );
    });

    const handleNext = useCallback(() => {
      if (items.length - numberOfItems - 1 >= pos - minItems) setPos(pos + 1);
    }, [items.length, numberOfItems, minItems, pos]);

    const handlePrev = () => {
      if (pos > 0) setPos(pos - 1);
    };

    useEffect(() => {
      let timer = null;
      if (autoScroll) {
        timer = setInterval(() => {
          if (items.length - numberOfItems - 1 >= pos) {
            handleNext();
          } else {
            setPos(0);
          }
        }, autoScrollDelay);
      }
      return () => {
        clearInterval(timer);
      };
    }, [autoScroll, autoScrollDelay, handleNext, items, numberOfItems, pos]);

    useEffect(() => {
      // Reset position to 0 on resizing between screen sizes to avoid the disappearing carousel problem.
      setPos(0);
      if (cardContainerRef.current) cardContainerRef.current.scrollLeft = 0;
    }, [isMobile]);

    const controlButtons = () => {
      if (!showControls || items.length <= numberOfItems - minItems)
        return null;
      const prevAvailable = pos > 0;
      const nextAvailable = pos - minItems < items.length - numberOfItems;
      return (
        <>
          {prevAvailable ? (
            <ControlButton
              onClick={handlePrev}
              direction="prev"
              bgColor={
                isUnIconArrow
                  ? 'var(--color-i-icon-dark)'
                  : 'var(--color-base-1)'
              }
              hovDisabled
              $topAdjustment={showNavigationButtons ? '18px' : '0px'}
            >
              <ChevronLeft
                size="24px"
                aria-hidden="true"
                color={
                  isUnIconArrow
                    ? 'var(--color-i-icon-light-tertiary)'
                    : 'var(--color-text-primary)'
                }
              />
            </ControlButton>
          ) : null}
          {nextAvailable ? (
            <ControlButton
              onClick={handleNext}
              direction="next"
              bgColor={
                isUnIconArrow
                  ? 'var(--color-i-icon-dark)'
                  : 'var(--color-base-1)'
              }
              hovDisabled
              $isUnIconArrow={isUnIconArrow}
              $topAdjustment={showNavigationButtons ? '18px' : '0px'}
            >
              <ChevronRight
                size="24px"
                aria-hidden="true"
                color={
                  isUnIconArrow
                    ? 'var(--color-i-icon-light-tertiary)'
                    : 'var(--color-text-primary)'
                }
              />
            </ControlButton>
          ) : null}
        </>
      );
    };

    const renderNavButtons = () => {
      const handlenavButtonClick = (position) => () => {
        setPos(position);
      };
      if (showNavigationButtons) {
        const navbuttons = items.map((_, index) => (
          <NavButton
            onClick={handlenavButtonClick(index)}
            key={index}
            $isSelected={index === pos}
          />
        ));
        return (
          <NavButtonsContainer $gridColumn={gridColumn}>
            {navbuttons}
          </NavButtonsContainer>
        );
      }
      return null;
    };

    const seeAllAnalytics = () => {
      if (handleClickSeeAll) {
        handleClickSeeAll();
      }
    };

    const onClickSeeAll = () => {
      pushRoute(href, seeAllLink);
    };

    return (
      <>
        {showInfo && (
          <CaraouselInfo ref={ref} className={className}>
            {showLabel && items.length > 0 && labelChild ? labelChild : null}
            {showLabel && items.length > 0 && !labelChild && label ? (
              <InfoSectionsDiv>
                <StyH4 as="h2">{label}</StyH4>
                {description && (
                  <Description variant="p2">{description}</Description>
                )}
              </InfoSectionsDiv>
            ) : null}
            <Grow />
            {seeAllLink && !isMobile && (
              <Link as={seeAllLink} href={href} onClick={seeAllAnalytics}>
                <BoldP1 color="var(--color-i-green)">SEE ALL</BoldP1>
              </Link>
            )}
          </CaraouselInfo>
        )}
        <CarouselWrapper
          gridColumn={gridColumn}
          ref={viewRef}
          $css={$css}
          $hasSeeAll={seeAllLink && isMobile}
        >
          <CardContainer
            $gridColumnGap={gridColumnGap}
            $showBottomGap={showBottomGap}
            $gridTemplateColumns={gridTemplateColumns}
            pos={pos}
            $numberOfItemsToShow={numberOfItems}
            $nItems={finalItems.length}
            ref={cardContainerRef}
          >
            {finalItems}
          </CardContainer>
          {controlButtons()}
        </CarouselWrapper>
        {renderNavButtons()}
        {!seeAllLink ? null : (
          <SeeAll label="See All" onClick={onClickSeeAll} />
        )}
      </>
    );
  }
);

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
  showInfo: PropTypes.bool,
  showBottomGap: PropTypes.bool,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  seeAllLink: PropTypes.string,
  href: PropTypes.string,
  showControls: PropTypes.bool,
  numberOfItems: PropTypes.number,
  minItems: PropTypes.number,
  gridColumn: PropTypes.string,
  itemGridColumn: PropTypes.string,
  gridColumnGap: PropTypes.string,
  className: PropTypes.string,
  showNavButtons: PropTypes.bool,
  gridTemplateColumns: PropTypes.number,
  showLabel: PropTypes.bool,
  viewRef: PropTypes.shape({ current: PropTypes.oneOfType([PropTypes.any]) }),
  $css: PropTypes.string,
  autoScroll: PropTypes.bool,
  autoScrollDelay: PropTypes.number,
  handleClickSeeAll: PropTypes.func,
  isUnIconArrow: PropTypes.bool,
  description: PropTypes.string
};

Carousel.defaultProps = {
  showInfo: true,
  gridTemplateColumns: 600,
  showBottomGap: true,
  label: null,
  seeAllLink: null,
  href: null,
  showControls: true,
  numberOfItems: 3,
  minItems: 0,
  gridColumn: 'span 18',
  itemGridColumn: null,
  gridColumnGap: null,
  className: '',
  showNavButtons: false,
  showLabel: true,
  viewRef: null,
  $css: '',
  autoScroll: false,
  autoScrollDelay: 5000,
  handleClickSeeAll: () => {},
  isUnIconArrow: false,
  description: ''
};
export default Carousel;
