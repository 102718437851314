import Paper from 'aquilla/core/atoms/Paper';
import Typography from 'aquilla/core/atoms/Typography';
import styled from '@emotion/styled';
import Image from '@base/FutureImage';
import { mediaQuery, Spacings } from '@styles/index';
import useRootPageData from '@api/hooks/home/useRootPageData';

const Content = styled.div`
  ${mediaQuery.aboveMobile} {
    display: none;
  }
  ${mediaQuery.uptoMobile} {
    margin-top: ${Spacings.SPACING_8B};
    display: flex;
    height: 333px;
    padding: 0 16px;
    flex-flow: column wrap;
    justify-content: center;
    & > div:last-child {
      margin-right: 0;
    }
    & > div:nth-child(3) {
      margin-bottom: 0;
    }
    & > div:nth-child(4) {
      margin-right: 0;
    }
  }
`;

const Card = styled(Paper)`
  ::before {
    padding: 100px;
  }
  ${mediaQuery.uptoMobile} {
    overflow: hidden;
    position: relative;
    padding: 20px;
    min-width: 156px;
    width: calc(50% - 22px);
    height: 100px;
    display: inline-block;
    margin: 0 12px 16px 0;
    background: transparent;
  }
`;

const CardImageWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: -7.5px;
  z-index: 1;
`;

const CardImage = styled(Image)`
  object-fit: contain;
`;

const CardDesc = styled(Typography)`
  display: inline-block;
`;
const CardCount = styled(CardDesc)`
  color: var(--color-i-green);
`;

const CardTitle = styled(Typography)`
  margin-bottom: ${Spacings.SPACING_1B};
`;

const Stats = () => {
  const {
    rootPageData: { stats }
  } = useRootPageData();

  return (
    <Content>
      {(stats || []).map((stat) => (
        <Card key={stat.id}>
          <div>
            <CardTitle variant="p2">{stat.title}</CardTitle>
            <CardDesc variant="h4">{stat.subTitle}</CardDesc>
            <CardCount variant="h4">+</CardCount>
          </div>
          <CardImageWrapper>
            <CardImage
              src={stat.urls?.default}
              alt={stat.title}
              height={105}
              width={160}
            />
          </CardImageWrapper>
        </Card>
      ))}
    </Content>
  );
};
export default Stats;
