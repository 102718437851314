import useFetch from '@hooks/useFetch';
import { emptyList } from '@constants/empty';

const useMobileLoginCountries = () => {
  const { data: countries } = useFetch(
    'v2/internationalization/countries/?sms_eligible=true'
  );
  return countries || emptyList;
};

export default useMobileLoginCountries;
