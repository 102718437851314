import { emptyList } from '@constants/empty';
import useFetch from '@hooks/useFetch';
import { useMemo } from 'react';

const getSegregateGoalGroupsData = (goals = emptyList) => {
  const goalGroups = [];
  let popularGoals = [];
  goals.forEach((item) => {
    if (item && item.name === 'Popular Topics') {
      popularGoals = item.children;
    }
    if (item.uid && item?.children?.length > 0) {
      if (!item.children[0].children.length) {
        goalGroups.push(item);
        return;
      }

      return item.children.forEach((goalGroup) => {
        goalGroups.push(goalGroup);
      });
    }
  });
  return { goalGroups, popularGoals };
};

const useOnboardingGoals = (
  options = {
    fallbackData: undefined
  },
  include_icons = true,
  include_platform_goals = true
) => {
  const key = `v1/topology/topology-onboarding/?limit=20&scope=paid&include_icons=${include_icons}&include_platform_goals=${include_platform_goals}`;
  const { data, isValidating, ...rest } = useFetch(key, {
    fallbackData: options.fallbackData
  });
  const onboardingGoals = data?.results ?? emptyList;

  const { goalGroups, popularGoals } = useMemo(
    () => getSegregateGoalGroupsData(onboardingGoals),
    [onboardingGoals]
  );
  return {
    key,
    isValidating,
    goalGroups,
    popularGoals,
    onboardingGoals: data?.results ?? emptyList,
    ...rest
  };
};

export default useOnboardingGoals;
