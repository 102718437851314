export const EVALUATION_STATUS = {
  PENDING: 4,
  ASSIGNED: 20,
  SKIPPED: 30,
  SCORES_GIVEN: 40,
  COMPLETED: 50,
  DELETED: 100
};

export const QUESTION_TYPE_MAP = {
  SINGLE: 1,
  MCQ: 2,
  INTEGER: 3
};
export const QUESTION_SUB_TYPE_MAP = {
  NO_SUBTYPE: 1
};

export const TEST_CREATION_FLOW_TYPE = {
  MANUAL_CREATION: 1,
  AUTOFILLED_CREATION: 2
};

export const BOOLEAN_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const DIFFICULTY_LEVEL_MAP = {
  ALL_DIFFICULTY: -1,
  VERY_EASY: 1,
  EASY: 2,
  MODERATE: 3,
  HARD: 4,
  VERY_HARD: 5
};

export const DIFFICULTY_LEVEL_LABEL_MAP = {
  [DIFFICULTY_LEVEL_MAP.VERY_EASY]: 'Very easy',
  [DIFFICULTY_LEVEL_MAP.EASY]: 'Easy',
  [DIFFICULTY_LEVEL_MAP.MODERATE]: 'Moderate',
  [DIFFICULTY_LEVEL_MAP.HARD]: 'Hard',
  [DIFFICULTY_LEVEL_MAP.VERY_HARD]: 'Very hard'
};

export const DIFFICULTY_LEVEL_TYPES = [
  {
    value: DIFFICULTY_LEVEL_MAP.VERY_EASY,
    label: DIFFICULTY_LEVEL_LABEL_MAP[DIFFICULTY_LEVEL_MAP.VERY_EASY]
  },
  {
    value: DIFFICULTY_LEVEL_MAP.EASY,
    label: DIFFICULTY_LEVEL_LABEL_MAP[DIFFICULTY_LEVEL_MAP.EASY]
  },
  {
    value: DIFFICULTY_LEVEL_MAP.MODERATE,
    label: DIFFICULTY_LEVEL_LABEL_MAP[DIFFICULTY_LEVEL_MAP.MODERATE]
  },
  {
    value: DIFFICULTY_LEVEL_MAP.HARD,
    label: DIFFICULTY_LEVEL_LABEL_MAP[DIFFICULTY_LEVEL_MAP.HARD]
  },
  {
    value: DIFFICULTY_LEVEL_MAP.VERY_HARD,
    label: DIFFICULTY_LEVEL_LABEL_MAP[DIFFICULTY_LEVEL_MAP.VERY_HARD]
  }
];

export const QUESTION_TYPES = [
  { label: 'Single choice correct', value: QUESTION_TYPE_MAP.SINGLE },
  { label: 'Multiple choice correct', value: QUESTION_TYPE_MAP.MCQ },
  { label: 'Integer', value: QUESTION_TYPE_MAP.INTEGER }
];

export const DESCRIPTIVE_QUESTION_TYPES_MAP = {
  FACT: 0,
  NUMERICAL: 1,
  CONCEPT: 2
};

export const DESCRIPTIVE_QUESTION_TYPES = [
  { value: DESCRIPTIVE_QUESTION_TYPES_MAP.FACT, label: 'Fact' },
  { value: DESCRIPTIVE_QUESTION_TYPES_MAP.NUMERICAL, label: 'Numerical' },
  { value: DESCRIPTIVE_QUESTION_TYPES_MAP.CONCEPT, label: 'Concept' }
];

export const INTEGER_TYPE_MAP = {
  INTEGER: 0,
  ALPHA: 1
};

export const INTEGER_TYPES = [
  { label: 'Integer', value: 0 },
  { label: 'Alphanumeric', value: 1 }
];

export const ANSWER_STATUS = {
  CORRECT_ATTEMPT: 1,
  INCORRECT_ATTEMPT: 2,
  SKIPPED: 3
};

export const NO_PREFRENCE = { display_name: 'No preference', code: -1 };
export const ENGLISH_PREFRENCE = { display_name: 'English', code: 1 };
export const HINDI_PREFRENCE = { display_name: 'Hindi', code: 2 };

export const PROGRAMME_LABEL_MAPPING = {
  1: 'FULL SYLLABUS TEST',
  2: 'MINOR TEST',
  3: 'PREVIOUS YEAR PAPERS'
};

export const PROGRAMME_CODE_MAPPING = {
  FULL_SYLLABUS: 1,
  MINOR_TEST: 2,
  PREVIOUS_YEAR: 3
};
