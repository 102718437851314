import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import { mediaQuery, Spacings } from '@styles/index';
import Image from '@base/FutureImage';

const CardWrapper = styled.div`
  background: var(--color-i-white);
  border-radius: 8px;
  box-shadow: ${boxShadowGenerator()};
  width: 284px;
  height: 180px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  ${mediaQuery.uptoTablet} {
    width: 201px;
    height: 100px;
  }
`;

const CardImageWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: -7.5px;
  z-index: 1;
`;

const CardImage = styled(Image)`
  ${mediaQuery.uptoTablet} {
    width: 175px;
    height: 120px;
    object-fit: contain;
  }
`;

const Left = styled.div`
  padding-left: 24px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const Row = styled.div`
  display: flex;
`;

const Label = styled.span`
  font-size: 40px;
  font-weight: bold;
  color: var(--color-i-black);
  line-height: 120%;
  ${mediaQuery.uptoTablet} {
    font-size: 18px;
  }
`;

const GreenLabel = styled(Label)`
  color: var(--color-i-green);
`;

const SecText = styled.span`
  font-size: 20px;
  line-height: 150%;
  color: var(--color-i-black);
  margin-bottom: ${Spacings.SPACING_2B};
  ${mediaQuery.uptoTablet} {
    font-size: 14px;
  }
`;

const StatsCard = ({ className, label, text, imageUrl }) => (
  <CardWrapper margin className={className}>
    <Left>
      <SecText>{text}</SecText>
      <Row>
        <Label>{label}</Label>
        <GreenLabel>+</GreenLabel>
      </Row>
    </Left>
    <CardImageWrapper>
      <CardImage src={imageUrl} alt={text} height={185} width={280} />
    </CardImageWrapper>
  </CardWrapper>
);

export default StatsCard;

StatsCard.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired
};

StatsCard.defaultProps = {
  className: ''
};
